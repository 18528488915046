import { Components, Theme } from '@mui/material/styles';

import { rootElement } from '../constants';

export const muiDialogOverride: Components<Omit<Theme, 'components'>>['MuiDialog'] = {
  defaultProps: {
    container: rootElement
  },
  styleOverrides: {
    paperWidthLg: {
      width: '814px'
    }
  }
};
