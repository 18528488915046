import { flatten } from 'lodash';
import uniq from 'lodash/uniq';
import { RecursiveKeyOf } from 'lkh-portal-ui-library/dist/models';
import { Application, Partner, PersonRoleEnum } from 'models/extension-generated';
import { mapToApplicationFields } from './utils';

const holderStaticFields: Array<RecursiveKeyOf<Partner>> = [
  'firstname',
  'lastname',
  'birthDate',
  'gender',
  'maritalStatus',
  'applicationInformation.insuranceNumberLKH',
  'permanentResidence.street',
  'applicationInformation.hasInsolvency',
  'permanentResidence.houseNumber',
  'permanentResidence.postalCode',
  'permanentResidence.city',
  'taxNumber',
  'applicationInformation.hasUsedEmergencyTariff',
  'applicationInformation.hasInsuranceDept',
  'permanentResidence.email',
  'permanentResidence.phone',
  'livingInGermany'
];

const contributorStaticFields: Array<RecursiveKeyOf<Partner>> = [
  'firstname',
  'lastname',
  'permanentResidence.street',
  'gender',
  'permanentResidence.houseNumber',
  'permanentResidence.postalCode',
  'permanentResidence.city',
  'permanentResidence.country',
  'bankDetails.iban',
  'bankDetails.bankName',
  'bankDetails.bicNumber',
  'bankDetails.hasPaymentAuthorization'
];

export const getInsuranceHolderFields = (
  application: Application
): RecursiveKeyOf<Application>[] => {
  const holderAndContributor = application.partners.filter(
    ({ roles }) =>
      roles.includes(PersonRoleEnum.POLICY_HOLDER) ||
      roles.includes(PersonRoleEnum.PAYMENT_CONTRIBUTOR)
  );

  const partnerFields = flatten(
    holderAndContributor.map((partner) => {
      const { id, roles } = partner;

      if (!id) return [];

      let fields: RecursiveKeyOf<Application>[] = [];

      if (roles.includes(PersonRoleEnum.POLICY_HOLDER)) {
        const holderFields = mapToApplicationFields(id, holderStaticFields);
        fields = fields.concat(holderFields);
      }

      if (roles.includes(PersonRoleEnum.PAYMENT_CONTRIBUTOR)) {
        const paymentContributorFields = mapToApplicationFields(id, contributorStaticFields);

        fields = fields.concat(paymentContributorFields);
      }

      return uniq(fields);
    })
  );
  return [...partnerFields, 'agentId', 'subAgentId'];
};
