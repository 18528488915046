import { Partner, Question, QuestionTypeEnum } from 'models/extension-generated';
import { formatGermanDate } from 'utils/dates';
import { formatBoolean, formatEnum } from 'utils/formatters';
import { HealthQuestion } from 'pages/DigitalOrderPage/models/insuredPersonDetailModels';

export const mapHealthQuestion =
  (partner: Partner) =>
  (questionDef: Question): HealthQuestion => {
    const question = partner.applicationInformation?.health?.find((q) => q.id === questionDef.id);
    const formattedAnswer = (() => {
      switch (questionDef?.questionType) {
        case QuestionTypeEnum.CLOSED_QUESTION:
          return formatBoolean(question?.answer);
        case QuestionTypeEnum.DATE_QUESTION:
          return formatGermanDate(question?.answer);
        default:
          return question?.answer;
      }
    })();

    const mappedDetails = (question?.details || []).map((detail) => {
      return {
        ...detail,
        treatmentType: formatEnum(detail.treatmentType, 'wizard.healthSection', 'common') || ''
      };
    });
    return {
      id: questionDef.id,
      text: questionDef?.text,
      answer: formattedAnswer,
      details: mappedDetails
    };
  };
