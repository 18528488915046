import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import { AccordionHandle, Label } from 'lkh-portal-ui-library';
import { formatGermanDate } from 'utils/dates';
import { SimpleTable } from '../SimpleTable/SimpleTable';
import { InsuredPersonDetailProps } from 'pages/DigitalOrderPage/models/insuredPersonDetailModels';
import { optionalValue, formatBoolean } from 'utils/formatters';
import { HealthQuestions } from '../HealthQuestions/HealthQuestions';

export const InsuredPersonDetail = forwardRef<AccordionHandle, InsuredPersonDetailProps>(
  (props, ref) => {
    const { t } = useTranslation('view360');
    return (
      <div className="row reset-container m-t-24">
        <div className="row m-b-32">
          <div className="col-6">
            <SimpleTable
              title={t('person.attributes.personalData')}
              rows={[
                {
                  label: t(`person.attributes.salutation`),
                  value: optionalValue(props.salutation)
                },
                {
                  label: t(`person.attributes.birthDate`),
                  value: optionalValue(props.birthDate, formatGermanDate)
                },
                {
                  label: t(`person.attributes.academicTitle`),
                  value: optionalValue(props.academicTitle)
                },
                {
                  label: t(`person.attributes.firstName`),
                  value: optionalValue(props.firstName)
                },
                { label: t(`person.attributes.lastName`), value: optionalValue(props.lastName) }
              ]}
            />
          </div>

          <div className="col-6">
            <SimpleTable
              title={t('person.attributes.companyData')}
              rows={[
                {
                  label: t(`person.attributes.professionalPosition`),
                  value: optionalValue(props.professionalPosition)
                },
                {
                  label: t(`person.attributes.currentOccupation`),
                  value: optionalValue(props.currentOccupation)
                }
              ]}
            />
          </div>
        </div>

        <div className="row m-b-32">
          <div className="col-6">
            <SimpleTable
              title={t('person.attributes.address')}
              rows={[
                {
                  label: t(`person.attributes.isLivingInGermany`),
                  value: optionalValue(props.isLivingInGermany, formatBoolean)
                },
                {
                  label: t(`person.attributes.street`),
                  value: optionalValue(props.street)
                },
                {
                  label: t(`person.attributes.houseNumber`),
                  value: optionalValue(props.houseNumber)
                },
                {
                  label: t(`person.attributes.postalCode`),
                  value: optionalValue(props.postalCode)
                },
                { label: t(`person.attributes.city`), value: optionalValue(props.city) },
                {
                  label: t(`person.attributes.hasForeignResidence`),
                  value: optionalValue(props.hasForeignResidence, formatBoolean)
                },
                { label: t(`person.attributes.country`), value: optionalValue(props.country) }
              ]}
            />
          </div>
        </div>

        <div className="grid-res-2">
          {(props.stationaryHealthQuestions?.questions || []).length > 0 && (
            <div>
              <div className="row">
                <div className="col my-m">
                  <Label bold className="text-secondary-600 ">
                    {t('insuredPerson.health.stationaryHealthQuestionsTitle')}
                  </Label>
                </div>
                <HealthQuestions {...props.stationaryHealthQuestions} />
              </div>
            </div>
          )}

          {(props.teethHealthQuestions?.questions || []).length > 0 && (
            <div>
              <div className="row">
                <div className="col my-m">
                  <Label bold className="text-secondary-600">
                    {t('insuredPerson.health.teethHealthQuestionsTitle')}
                  </Label>
                </div>
                <HealthQuestions {...props.teethHealthQuestions} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);
