import { TariffSectionEnum } from 'models/extension-generated';

/**
 * These are documents that should be downloaded together
 * with rest of the tariff documents.
 *
 */
export const STATIC_DOCUMENT_IDS = ['K06_49', 'K01_07', 'K01_19', 'K06_31', 'K01_50'];

/**
 * Tariff sections that can be deleted
 */
export const DELETEABLE_TARIFF_SECTIONS = [
  TariffSectionEnum.KRANKENHAUSTAGEGELD,
  TariffSectionEnum.KRANKENTAGEGELD,
  TariffSectionEnum.PFLEGEGELD,
  TariffSectionEnum.PFLEGEPFLICHT,
  TariffSectionEnum.OPTIONAL,
  TariffSectionEnum.AUSLANDSREISEKRANKENVERSICHERUNG
];

/**
 * Document that is present if any insured person is over 60 yo
 */
export const AGE_OVER_60_DOCUMENT_ID = 'ENV61_01';
export const DISCLAIMER_DOCUMENT = 'K01_07';

/**
 * Event ids for tracking by analytics
 * Warning: These ids have dependency on external analytics service.
 */
export enum EVENT_IDS {
  /**
   * Id for navigation next button <ContinueButton/>
   * Is combined with the current route to create a unique event id
   */
  NEXT = 'next_button',

  /**
   * Present on insurance holder page
   * Id for when offer is downloaded
   */
  DOWNLOAD_OFFER = 'download_offer',

  /**
   * Present on DonePage
   * Id for when application is downloaded
   */
  DOWNLOAD_APPLICATION = 'download_application',

  /**
   * Gesetzliche Anzeigepflicht
   * - hasLegalObligationToNotify
   */
  HEALTH_QUESTIONS_DISCLAIMER = 'health_questions_disclaimer',

  /**
   * Payment authorization disclaimer
   * - hasPaymentAuthorization
   */
  SEPA_CHECKBOX = 'sepa_checkbox',

  /**
   * Datenschutzhinweise
   * - acceptsDataProtection
   */
  DATA_PROTECTION_CHECKBOX = 'data_privacy_checkbox',

  /**
   * Einwilligung Beratungsverzicht
   * - acceptsToWaiveAdvice
   */
  WAIVE_ADVICE_CHECKBOX = 'waive_advice_checkbox',

  /**
   * Vertragsinformationen:
   * Ich bestätige, dass ich die vorstehenden Vertragsinformationen zur Kenntnis genommen habe und akzeptiere. *
   * - acceptsContractInformation
   */
  CONTRACT_INFORMATION_CHECKBOX = 'contract_information_checkbox',

  /**
   * Schweigepflichtsentbindungserklärung (acceptsDataCollection)
   * - acceptsDataCollection
   */
  DATA_PROCESSING_CHECKBOX = 'data_processing_checkbox',

  /**
   * „Datennutzung und -verarbeitung für Werbezwecke"
   * - acceptsPromotionalPurposes
   */
  PROMOTIONAL_PURPOSES_CHECKBOX = 'promotional_purposes_checkbox',

  /**
   * - acceptsPromotionalPurposesPhone
   */
  PHONE_NUMBER_CHECKBOX = 'phone_number_checkbox',

  /**
   * - acceptsPromotionalPurposesEmail
   */
  EMAIL_CHECKBOX = 'email_checkbox'
}
