/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GenderEnum } from './GenderEnum';
import type { SalutationEnum } from './SalutationEnum';

/**
 * This is the generic person object
 */
export type Person = {
    /**
     * First name of the Person.
     */
    firstname: string;
    /**
     * Last name of the Person.
     */
    lastname: string;
    title?: Person.title;
    /**
     * Birth date of the Person.
     */
    birthDate: string;
    gender: GenderEnum;
    salutation: SalutationEnum;
    roles: Array<'INSURED_PERSON' | 'BROKER' | 'PAYMENT_CONTRIBUTOR' | 'SERVICE_RECIPIENT' | 'POLICY_HOLDER'>;
    /**
     * Marital status (Familienstand)
     */
    maritalStatus?: Person.maritalStatus;
    personType?: Person.personType;
};

export namespace Person {

    export enum title {
        DR = 'DR',
        PROF = 'PROF',
        PROF_DR = 'PROF_DR',
    }

    /**
     * Marital status (Familienstand)
     */
    export enum maritalStatus {
        SINGLE = 'SINGLE',
        MARRIED = 'MARRIED',
        PARTNERED = 'PARTNERED',
        WIDOWED = 'WIDOWED',
    }

    export enum personType {
        NATURAL = 'NATURAL',
        LEGAL = 'LEGAL',
    }


}

