/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Role of the person within the application (reflects the adesso roles)
 */
export enum PersonRoleEnum {
    INSURED_PERSON = 'INSURED_PERSON',
    BROKER = 'BROKER',
    PAYMENT_CONTRIBUTOR = 'PAYMENT_CONTRIBUTOR',
    SERVICE_RECIPIENT = 'SERVICE_RECIPIENT',
    POLICY_HOLDER = 'POLICY_HOLDER',
}
