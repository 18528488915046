import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import { AccordionHandle } from 'lkh-portal-ui-library';
import { formatGermanDate } from 'utils/dates';
import { SimpleTable } from '../SimpleTable/SimpleTable';
import { optionalValue, formatBoolean } from 'utils/formatters';

import { PolicyHolderDetailProps } from 'pages/DigitalOrderPage/models/insuredPersonDetailModels';

export const PolicyHolderDetail = forwardRef<AccordionHandle, PolicyHolderDetailProps>(
  (props, ref) => {
    const { t } = useTranslation('view360');

    return (
      <div className="row reset-container m-t-24 m-b-24">
        <div className="col-6">
          <SimpleTable
            title={t('person.attributes.personalData')}
            rows={[
              {
                label: t(`person.attributes.salutation`),
                value: optionalValue(props.salutation)
              },
              {
                label: t(`person.attributes.birthDate`),
                value: optionalValue(props.birthDate, formatGermanDate)
              },
              {
                label: t(`person.attributes.academicTitle`),
                value: optionalValue(props.academicTitle)
              },
              { label: t(`person.attributes.firstName`), value: optionalValue(props.firstName) },
              { label: t(`person.attributes.lastName`), value: optionalValue(props.lastName) }
            ]}
          />
        </div>

        <div className="col-6">
          <SimpleTable
            title={t('person.attributes.companyData')}
            rows={[
              {
                label: t(`person.attributes.professionalPosition`),
                value: optionalValue(props.professionalPosition)
              },
              {
                label: t(`person.attributes.currentOccupation`),
                value: optionalValue(props.currentOccupation)
              }
            ]}
          />
        </div>

        <div className="row m-t-32">
          <div className="col-6">
            <SimpleTable
              title={t('person.attributes.address')}
              rows={[
                {
                  label: t(`person.attributes.isLivingInGermany`),
                  value: optionalValue(props.isLivingInGermany, formatBoolean)
                },
                { label: t(`person.attributes.street`), value: optionalValue(props.street) },
                {
                  label: t(`person.attributes.houseNumber`),
                  value: optionalValue(props.houseNumber)
                },
                {
                  label: t(`person.attributes.postalCode`),
                  value: optionalValue(props.postalCode)
                },
                { label: t(`person.attributes.city`), value: optionalValue(props.city) },
                {
                  label: t(`person.attributes.hasForeignResidence`),
                  value: optionalValue(props.hasForeignResidence, formatBoolean)
                },
                { label: t(`person.attributes.country`), value: optionalValue(props.country) }
              ]}
            />
          </div>

          <div className="col-6">
            <SimpleTable
              title={t('person.attributes.contactData')}
              rows={[
                { label: t(`person.attributes.email`), value: optionalValue(props.email) },
                { label: t(`person.attributes.phone`), value: optionalValue(props.phone) }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
);
