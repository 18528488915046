import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// main translation files
import header from './resources/de/header.json';
import common from './resources/de/common.json';
import navigation from './resources/de/navigation.json';
import footer from './resources/de/footer.json';
import dashboard from './resources/de/dashboard.json';
import enums from './resources/de/enums.json';
import productCard from './resources/de/productCard.json';
import productsPage from './resources/de/productsPage.json';
import contract from './resources/de/contract.json';
import policy from './resources/de/policy.json';
import formsPage from './resources/de/formsPage.json';
import formsFilter from './resources/de/formsFilter.json';
import view360 from './resources/de/view360.json';
import contractsEnums from './resources/de/contractsEnums.json';
import wizardContact from './resources/de/wizardContact.json';
import wizardRelationship from './resources/de/wizardRelationship.json';
import wizardPrivateHealthInsurance from './resources/de/wizardPrivateHealthInsurance.json';
import wizardOtherPersonalData from './resources/de/wizardOtherPersonalData.json';
import wizardHealth from './resources/de/wizardHealth.json';
import wizardBonity from './resources/de/wizardBonity.json';
import wizardBankDetails from './resources/de/wizardBankDetails.json';
import wizardGeneral from './resources/de/wizardGeneral.json';
import wizardTariffs from './resources/de/wizardTariffs.json';
import wizardSidebar from './resources/de/wizardSidebar.json';
import wizardValidation from './resources/de/wizardValidation.json';
import profile from './resources/de/profile.json';
import wizardDone from './resources/de/wizardDone.json';
import wizardOffer from './resources/de/wizardOffer.json';
import wizardSummary from './resources/de/wizardSummary.json';
import wizardLegal from './resources/de/wizardLegal.json';
import insuredPersonStep from './resources/de/insuredPersonStep.json';
import insuranceHolderStep from './resources/de/insuranceHolderStep.json';
import InsuredPersonForm from './resources/de/InsuredPersonForm.json';
import ExtendedInsuredPersonForm from './resources/de/ExtendedInsuredPersonForm.json';
import BankDetailsForm from './resources/de/BankDetailsForm.json';
import tariffSection from './resources/de/tariffSection.json';
import summaryPage from './resources/de/summaryPage.json';
import contactSupport from './resources/de/contactSupport.json';

export const resources = {
  de: {
    common,
    contract,
    dashboard,
    enums,
    header,
    navigation,
    footer,
    productCard,
    productsPage,
    formsPage,
    formsFilter,
    policy,
    profile,
    view360,
    tariffSection,
    contractsEnums,
    wizardContact,
    wizardRelationship,
    wizardPrivateHealthInsurance,
    wizardOtherPersonalData,
    wizardHealth,
    wizardBonity,
    wizardBankDetails,
    wizardGeneral,
    wizardTariffs,
    wizardSidebar,
    wizardDone,
    wizardValidation,
    wizardOffer,
    wizardSummary,
    wizardLegal,
    insuredPersonStep,
    insuranceHolderStep,
    InsuredPersonForm,
    ExtendedInsuredPersonForm,
    BankDetailsForm,
    summaryPage,
    contactSupport
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: window.localStorage.getItem('LANG') || 'de', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false
    },
    defaultNS: 'common',
    resources,
    ns: [
      'common',
      'dashboard',
      'enums',
      'header',
      'navigation',
      'footer',
      'productsPage',
      'productCard',
      'formsPage',
      'formsFilter',
      'policy',
      'profile',
      'view360',
      'tariffSection',
      'contractsEnums',
      'wizardContact',
      'wizardRelationship',
      'wizardPrivateHealthInsurance',
      'wizardOtherPersonalData',
      'wizardHealth',
      'wizardBonity',
      'wizardBankDetails',
      'wizardGeneral',
      'wizardTariffs',
      'wizardSidebar',
      'wizardDone',
      'wizardValidation',
      'wizardOffer',
      'wizardSummary',
      'wizardLegal',
      'insuredPersonStep',
      'insuranceHolderStep',
      'InsuredPersonForm',
      'ExtendedInsuredPersonForm',
      'BankDetailsForm',
      'summaryPage',
      'contactSupport'
    ],
    compatibilityJSON: 'v3'
  });

export default i18n;
