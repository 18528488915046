import { axios } from '../services/axios';
import { AGE_OVER_60_DOCUMENT_ID, DISCLAIMER_DOCUMENT } from 'pages/DigitalOrderPage/constants';

export const downloadDisclaimerDocument = async (applicationStart: string | undefined) => {
  try {
    const response = await downloadFormByReferenceId(DISCLAIMER_DOCUMENT, applicationStart || '');
    downloadData(response.data, 'Informationen zum Datenschutz.pdf');
  } catch (error) {
    throw new Error(`Error occured during download: ${error}`);
  }
};

export function downloadForm(id: string) {
  return axios.get(`/api/form/${id}`, { responseType: 'blob' });
}

export function downloadFormByReferenceId(id: string, date: string) {
  return axios.get(`/api/public/form/getByReferenceId/${id}?date=${date}`, {
    responseType: 'blob'
  });
}

export const downloadDocument = async (documentId: string) => {
  const response = await downloadForm(documentId);

  const fileURL = window.URL.createObjectURL(new Blob([response.data]));
  const fileLink = document.createElement('a');

  fileLink.href = fileURL;
  fileLink.download = documentId;
  document.body.appendChild(fileLink);

  fileLink.click();
  fileLink.remove();
};

export const downloadData = (data: any, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename); //or any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadCsv = (data: any, filename: string) => {
  const url = window.URL.createObjectURL(
    new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), data], {
      type: 'text/csv;charset=utf-8;'
    })
  );

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
export const downloadAgeOver60Form = async (date: string): Promise<void> => {
  try {
    const response = await downloadFormByReferenceId(AGE_OVER_60_DOCUMENT_ID, date);
    downloadData(response.data, `${AGE_OVER_60_DOCUMENT_ID}.pdf`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error occurred during download:', error);
    throw new Error(`Error occurred during download: ${error}`);
  }
};
