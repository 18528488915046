import { Body, Label } from 'lkh-portal-ui-library';

import styles from './TariffDescription.module.scss';

type TariffDescriptionProps = {
  description: string;
};

export const TariffDescription = ({ description }: TariffDescriptionProps) => {
  const rawDescription = description.replace(/\\n/g, '\n');

  const pattern = /\(f\)(.*?)\(\/f\)/g;
  const tariffName = rawDescription
    ?.match(pattern)
    ?.map((match) => match.replace(/\(f\)|\(\/f\)/g, ''));
  const tariffDescription = rawDescription?.replace(pattern, '').trim();

  return (
    <div className={styles.tariffDescription}>
      <Body small bold className="d-block m-b-12">
        {tariffName}
      </Body>
      <Label regular>{tariffDescription}</Label>
    </div>
  );
};
