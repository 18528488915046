export const colors = {
  primary: {
    '100': '#0A773B',
    '90': '#0D9E4F',
    '80': '#10C663',
    '70': '#40D182',
    '60': '#70DDA1',
    '50': '#9FE8C1',
    '40': '#CFF4E0',
    '30': '#E6FAEF',
    '20': '#F2FFF8'
  },
  secondary: {
    '100': '#CCC011',
    '90': '#E7DA13',
    '80': '#F0E648',
    '70': '#F3EB6D',
    '60': '#F9F5B6',
    '50': '#F9F5B6',
    '40': '#FCFADA',
    '30': '#FFFEEB',
    '20': '#FFFEF5'
  },
  navigation: {
    '100': '#003B39',
    '90': '#004F4C',
    '80': '#00635F',
    '70': '#33827F',
    '60': '#66A19F',
    '50': '#99C1C0',
    '40': '#CCE0DF',
    '30': '#DFF0EF',
    '20': '#EDFAF9'
  },
  progress: {
    '100': '#102814',
    '90': '#16361C',
    '80': '#1B4322',
    '70': '#378646',
    '60': '#5EBD70',
    '50': '#A1D9AB',
    '40': '#E4F4E7',
    '30': '#E9F6EB',
    '20': '#EFF8F1'
  },
  highlight: {
    '100': '#006765',
    '90': '#008A87',
    '80': '#00ACA9',
    '70': '#33BDBA',
    '60': '#66CDCB',
    '50': '#99DEDD',
    '40': '#CCEEEE',
    '30': '#D6F1F1',
    '20': '#E0F5F5'
  },
  text: {
    '100': '#18191A',
    '80': '#39393B',
    '60': '#69696E',
    '40': '#ACACB3',
    '20': '#DDDDE6'
  },
  title: {
    '100': '#14332D',
    '80': '#194038',
    '60': '#476660',
    '40': '#758C87',
    '20': '#A3B3AF'
  },
  white: {
    '100': '#FFFFFF',
    '80': '#FFFFFFcc',
    '60': '#FFFFFF99',
    '40': '#FFFFFF66',
    '20': '#FFFFFF33'
  },
  surface: {
    '100': '#FEFEFE',
    '80': '#FCFCFC',
    '60': '#fafafa',
    '40': '#f0f0f0',
    '20': '#e0e0e0'
  },
  success: {
    '100': '#064E3B',
    '90': '#065F46',
    '80': '#047857',
    '70': '#059669',
    '60': '#10B981',
    '50': '#34D399',
    '40': '#6EE7B7',
    '30': '#A7F3D0',
    '20': '#D1FAE5',
    '10': '#ECFDF5'
  },
  warning: {
    '100': '#78350F',
    '90': '#92400E',
    '80': '#B45309',
    '70': '#D97706',
    '60': '#F59E0B',
    '50': '#FBBF24',
    '40': '#FCD34D',
    '30': '#FDE68A',
    '20': '#FEF3C7',
    '10': '#FFFBEB'
  },
  danger: {
    '100': '#610316',
    '90': '#8A041A',
    '80': '#AB091E',
    '70': '#CF1124',
    '60': '#E12D39',
    '50': '#EF4E4E',
    '40': '#F86A6A',
    '30': '#FF9B9B',
    '20': '#FFBDBD',
    '10': '#FFE3E3'
  },
  neutral: {
    '100': '#050B1E',
    '90': '#0A153C',
    '80': '#102059',
    '70': '#152A77',
    '60': '#1A3595',
    '50': '#485DAA',
    '40': '#7686BF',
    '30': '#A3AED5',
    '20': '#D1D7EA',
    '10': '#EBEFFA'
  }
} as const;
