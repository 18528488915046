import classNames from 'classnames';
import { Caption } from 'lkh-portal-ui-library';

import styles from './ProductLabel.module.scss';

export const ProductLabel = (props: { label: string }) => {
  return (
    <Caption medium className={classNames(styles.product, 'p-v-4', 'p-h-12', 'm-0')}>
      {props.label}
    </Caption>
  );
};
