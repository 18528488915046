/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SearchOperationEnum {
    EQUALS = 'EQUALS',
    NOT_EQUALS = 'NOT_EQUALS',
    LIKE = 'LIKE',
    START_WITH = 'START_WITH',
    GREATER_THAN = 'GREATER_THAN',
    LESS_THAN = 'LESS_THAN',
    GREATER_THAN_EQUALS = 'GREATER_THAN_EQUALS',
    LESS_THAN_EQUALS = 'LESS_THAN_EQUALS',
    IN = 'IN',
    NOT_IN = 'NOT_IN',
    NOT_LIKE = 'NOT_LIKE',
    IS_EMPTY = 'IS_EMPTY',
    NOT_EMPTY = 'NOT_EMPTY',
    IS_MEMBER = 'IS_MEMBER',
    IS_NOT_MEMBER = 'IS_NOT_MEMBER',
}
