/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Tariff } from '../models/Tariff';
import type { TariffFilters } from '../models/TariffFilters';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TariffService {

    /**
     * Get all tariffs available by filters
     * @returns Tariff Tariffs successfully received
     * @throws ApiError
     */
    public static getTariffsByFilters({
        requestBody,
    }: {
        requestBody: TariffFilters,
    }): CancelablePromise<Array<Tariff>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tariff',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all tariffs available by filters
     * @returns Tariff Tariffs successfully received
     * @throws ApiError
     */
    public static getPublicTariffsByFilters({
        requestBody,
    }: {
        requestBody: TariffFilters,
    }): CancelablePromise<Array<Tariff>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/tariff',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal server error`,
            },
        });
    }

}
