/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApplicationStatusEnum {
    OFFER_CREATED = 'OFFER_CREATED',
    OFFER_REFUSED = 'OFFER_REFUSED',
    OFFER_PUBLISHED = 'OFFER_PUBLISHED',
    OFFER_INVALID = 'OFFER_INVALID',
    APPLICATION_CREATED = 'APPLICATION_CREATED',
    APPLICATION_RELEASED = 'APPLICATION_RELEASED',
    APPLICATION_REFUSED = 'APPLICATION_REFUSED',
    APPLICATION_TRANSMITTED = 'APPLICATION_TRANSMITTED',
    IN_RESEARCH = 'IN_RESEARCH',
    FOUR_EYES_TEST = 'FOUR_EYES_TEST',
    APPLICATION_POLICED = 'APPLICATION_POLICED',
    APPLICATION_NOT_POLICED = 'APPLICATION_NOT_POLICED',
    APPLICATION_PUBLISHED = 'APPLICATION_PUBLISHED',
}
