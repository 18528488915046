import { Components, Theme } from '@mui/material/styles';
import { pickersDayClasses } from '@mui/x-date-pickers/PickersDay';
// When using TypeScript 4.x and above
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { colors } from 'styles/colors';

export const muiPickerDayOverride: Components<Omit<Theme, 'components'>>['MuiPickersDay'] = {
  styleOverrides: {
    root: {
      [`&.${pickersDayClasses.selected}`]: {
        ':focus': {
          backgroundColor: colors.primary[80],
          colors: colors.white[100]
        },
        backgroundColor: colors.primary[80],
        color: colors.white[100]
      }
    }
  }
};
