/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Gender of the partner.
 */
export enum GenderEnum {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    UNKNOWN = 'UNKNOWN',
}
