import { useTranslation } from 'react-i18next';
import { GhostButton, Loader, Body, LoaderType, getStringUuid } from 'lkh-portal-ui-library';

import { PersonRoleEnum } from 'models/extension-generated';
import styles from './AddPartnerButton.module.scss';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { useWizardNavigation } from 'hooks/useWizardNavigation';
import { WizardRoutesEnum } from 'utils/actions';
import { useApplicationValidation } from 'hooks/useApplicationValidation';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';

export const AddPartnerButton = ({ onPersonSwitch }: { onPersonSwitch?: () => void }) => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const { reducer, selectPartner, state, partnerId } = useHealthInsuranceContext();
  const { isValidating, validatePartner } = useApplicationValidation(state, reducer, partnerId);
  const { goTo } = useWizardNavigation();
  const { createPartner } = reducer;

  const handleAddInsuredPerson = async () => {
    try {
      const validationResult = await validatePartner(partnerId);

      if (validationResult.size === 0) {
        const id = getStringUuid();

        createPartner({ role: PersonRoleEnum.INSURED_PERSON, id });
        selectPartner(id);
        goTo(WizardRoutesEnum.PrivateData);
        onPersonSwitch?.();
      }
    } catch (error) {
      // TODO: handle error (TODO from agent-portal)
    }
  };
  if (isValidating) {
    // TODO set color of loader when other colors are available (TODO from agent-portal)
    return (
      <GhostButton disabled>
        <div className={styles.loadingButton}>
          <Body small medium className="m-r-8">
            {t('actions.validating')}
          </Body>
          <Loader className={styles.loader} type={LoaderType.Circular} />
        </div>
      </GhostButton>
    );
  }

  return (
    <GhostButton className="h-[44px]" onClick={handleAddInsuredPerson}>
      <PersonAddAlt1OutlinedIcon className="text-primary mr-[8px]" />
      <span className="tracking-normal text-[14px] font-[700] leading-[20px]">
        {t('actions.addInsuredPerson')}
      </span>
    </GhostButton>
  );
};
