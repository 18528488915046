import { Components, Theme } from '@mui/material/styles';
import { toggleButtonClasses } from '@mui/material/ToggleButton';
import { colors } from 'styles/colors';

import { fieldHeight } from '../constants';

export const muiToggleButtonOverride: Components<Omit<Theme, 'components'>>['MuiToggleButton'] = {
  defaultProps: {
    disableRipple: true
  },
  styleOverrides: {
    root: {
      variants: [
        {
          props: { size: 'small' },
          style: {
            height: '32px'
          }
        }
      ],
      height: fieldHeight,
      width: '200px',
      borderColor: colors.text[40],
      color: colors.text[80],
      textTransform: 'none',
      '&:hover': {
        backgroundColor: colors.surface[60],
        borderColor: colors.text[60]
      },
      [`&.${toggleButtonClasses.selected}`]: {
        backgroundColor: colors.navigation[80],
        color: colors.white[100],
        '&:hover': {
          backgroundColor: colors.navigation[80]
        },
        [`&.${toggleButtonClasses.disabled}`]: {
          backgroundColor: colors.text[20],
          color: '#00000042'
        }
      }
    }
  }
};
