import { PropsWithChildren } from 'react';

import styles from '../../WizardLayout.module.scss';

export const Actions = ({ children }: PropsWithChildren) => {
  return (
    <section className={styles.actions}>
      <div className={styles.actionButtons}>{children}</div>
    </section>
  );
};
