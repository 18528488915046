import { PropsWithChildren } from 'react';

import ContactSupport from 'components/ContactSupport';
import styles from './WizardLayout.module.scss';

type WizardLayoutProps = PropsWithChildren;

export const WizardLayout = ({ children }: WizardLayoutProps) => {
  return (
    <div className={styles.wizardLayout}>
      <div className={styles.main}>
        <ContactSupport />
      </div>
      {children}
    </div>
  );
};
