/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TariffSectionEnum {
    AMBULANT = 'AMBULANT',
    STATIONAER = 'STATIONAER',
    ZAHN = 'ZAHN',
    KRANKENTAGEGELD = 'KRANKENTAGEGELD',
    KRANKENHAUSTAGEGELD = 'KRANKENHAUSTAGEGELD',
    OPTIONAL = 'OPTIONAL',
    PFLEGEPFLICHT = 'PFLEGEPFLICHT',
    PFLEGEGELD = 'PFLEGEGELD',
    AUSLANDSREISEKRANKENVERSICHERUNG = 'AUSLANDSREISEKRANKENVERSICHERUNG',
}
