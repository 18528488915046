import { InsuranceHolderStep } from './subpages/InsuranceHolderStep';
import { InsuredPersonStep } from './subpages/InsuredPersonStep';
import { SummaryStep } from './subpages/SummaryStep';
import { WizardRoutesEnum } from 'utils/actions';

type WizardScreenProps = {
  currentScreen: WizardRoutesEnum;
};

export const WizardScreen = ({ currentScreen }: WizardScreenProps) => {
  switch (currentScreen) {
    case WizardRoutesEnum.PrivateData:
      return <InsuredPersonStep />;
    case WizardRoutesEnum.InsuranceHolder:
      return <InsuranceHolderStep />;
    case WizardRoutesEnum.Summary:
      return <SummaryStep />;
    default:
      throw new Error(`Invalid wizard screen: ${currentScreen}`);
  }
};
