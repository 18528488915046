import { CSSProperties } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails, { AccordionDetailsProps } from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { colors } from 'styles/colors';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    slotProps={{ transition: { unmountOnExit: true } }}
    {...props}
  />
))(() => ({
  padding: 0,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  }
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowDownwardIcon className="text-text-80" />} {...props} />
))(({ theme }) => ({
  backgroundColor: colors.surface[60],
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  },
  '&:hover': {
    backgroundColor: colors.surface[40]
  }
}));

export const AccordionDetails = styled(MuiAccordionDetails)<
  AccordionDetailsProps & { topBorderColor?: CSSProperties['color'] }
>(({ topBorderColor = colors.text[80] }) => ({
  padding: 0,
  borderTop: `1px solid ${topBorderColor}`
}));
