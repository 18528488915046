import {
  Action,
  FormState,
  useFormReducer,
  reducers,
  useExtendedReducer
} from 'lkh-portal-ui-library';
import { createContext, Dispatch, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { Application } from 'models/extension-generated';
import { createApplication } from '../utils';

import { useConfigContext } from 'contexts/ConfigContext';
/**
 * Context wrapper for everything related to the Application form for the Health Insurance
 */
export interface HealthInsuranceContextType {
  /**
   * Current Form state
   */
  state: FormState<Application>;

  /**
   * Currently selected partner unique identifier
   */
  partnerId: string;

  /**
   * Method to set the selected partner by ID
   */
  selectPartner: (id: string) => void;

  /**
   * Dispatch function for all Form actions
   * NOTE: prefered way to trigger actions is via calling the reducer directly
   */
  dispatch: Dispatch<Action>;

  /**
   * Extended reducer API
   * Supports all native Form actions as well as any further extension actions
   */
  reducer: ReturnType<typeof useExtendedReducer>;

  /**
   * Whether there is loading event in progress.
   * This variable is true in following cases:
   *  - application load
   *  - initial configuration load
   */
  isLoading: boolean;
}

type HealthInsuranceContextProviderProps = PropsWithChildren;

// eslint-disable-next-line
export const HealthInsuranceContext = createContext<HealthInsuranceContextType>(null!);

export function HealthInsuranceContextProvider({ children }: HealthInsuranceContextProviderProps) {
  const initialState = {
    model: createApplication()
  };

  const [state, dispatch] = useFormReducer<Application>(initialState, reducers);
  const reducer = useExtendedReducer(dispatch);
  const [partnerId, selectPartner] = useState<string>(state.model.partners[0].id!);

  const { isLoading: isConfigLoading } = useConfigContext();
  const ctx = useMemo(() => {
    return {
      state,
      dispatch,
      reducer,
      partnerId,
      selectPartner,
      isLoading: isConfigLoading
    };
  }, [state, dispatch, reducer, partnerId, selectPartner, isConfigLoading]);

  return <HealthInsuranceContext.Provider value={ctx}>{children}</HealthInsuranceContext.Provider>;
}

export function useHealthInsuranceContext(): HealthInsuranceContextType {
  const healthInsuranceContext = useContext(HealthInsuranceContext);

  if (!healthInsuranceContext) {
    throw new Error(
      'To use "useHealthInsuranceContext" some of the parent components must be in <HealthInsuranceContextProvider>'
    );
  }

  return healthInsuranceContext;
}
