import { Components, Theme } from '@mui/material/styles';
import { colors } from 'styles/colors';

export const muiLinkOverride: Components<Omit<Theme, 'components'>>['MuiLink'] = {
  styleOverrides: {
    root: {
      '&:hover': {
        color: colors.highlight[80]
      }
    }
  }
};
