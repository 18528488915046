import { ReactNode } from 'react';
import { BaseAddress } from '../models/portal-generated';

enum CurrencyEnum {
  EURO = 'EURO'
}

const CurrencyHtmlFormatted = {
  EURO: '\u20AC'
};

export function formatAddress(address: BaseAddress): string {
  return `${address.postalCode} ${address.city}, ${address.street} ${address.houseNumber}`;
}

export function formatPriceAsText(
  value?: number,
  currency: CurrencyEnum = CurrencyEnum.EURO
): string {
  return `${(value?.toFixed(2) || '').replace('.', ',')} ${CurrencyHtmlFormatted[currency]}`;
}

export function formatPrice(value?: number, currency: CurrencyEnum = CurrencyEnum.EURO): ReactNode {
  return (
    <>
      {(value?.toFixed(2) || '').replace('.', ',')} <>{CurrencyHtmlFormatted[currency]}</>
    </>
  );
}
