/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfigResponse } from '../models/ConfigResponse';
import type { TariffCards } from '../models/TariffCards';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConfigService {

    /**
     * Retrieves the configuration data for UI
     * @returns ConfigResponse The config retrieved.
     * @throws ApiError
     */
    public static getConfig(): CancelablePromise<ConfigResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/config',
        });
    }

    /**
     * Retrieves the configuration data for UI
     * @returns ConfigResponse The config retrieved.
     * @throws ApiError
     */
    public static getPublicConfig(): CancelablePromise<ConfigResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/config',
        });
    }

    /**
     * Retrieves the tarif card data for UI
     * @returns TariffCards The tariff card retrieved.
     * @throws ApiError
     */
    public static getTariffCards({
        birthDate,
        insuranceBegin,
    }: {
        /**
         * The date of birth of person
         */
        birthDate?: string,
        /**
         * The insurance start date
         */
        insuranceBegin?: string,
    }): CancelablePromise<TariffCards> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/tariffCard',
            query: {
                'birthDate': birthDate,
                'insuranceBegin': insuranceBegin,
            },
        });
    }

}
