import { useTranslation } from 'react-i18next';
import { FileDownloadBox } from 'lkh-portal-ui-library';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import { useParams } from 'react-router';
import { downloadData } from 'utils/fetchDocument';
import { LegalFooter } from 'components/LegalFooter';
import { GreenLogo } from 'components/Logo';
import { useState } from 'react';
import axios from 'axios';
import { EVENT_IDS } from 'pages/DigitalOrderPage/constants';

export const DoneStep = () => {
  const { t } = useTranslation('wizardDone');
  const { applicationId } = useParams();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const downloadFile = async (applicationId: string | undefined): Promise<void> => {
    setIsDownloading(true);
    setIsDone(false);
    try {
      if (applicationId) {
        const response = await axios.get(`/api/public/new/application/${applicationId}/order`, {
          responseType: 'blob'
        });
        downloadData(response.data, `${applicationId}.pdf`);
      }
    } catch (error) {
      // TODO handle error
    } finally {
      setIsDownloading(false);
      setIsDone(true);
    }
  };

  return (
    <div className="grid-mx-res grid-my-res">
      <div className="s:grid s:grid-cols-12 s:pl-[40px] m:pl-[80px] pl-[16px] hidden">
        <div className="col-span-4">
          <GreenLogo />
        </div>
      </div>

      <div className="flex items-center justify-center w-full mb-[48px]">
        <div>
          <div className="flex justify-center">
            <CheckCircleOutlineOutlinedIcon
              sx={{ width: 40, height: 40 }}
              className="text-logo-500"
            />
          </div>
          <div className="text-center tracking-normal text-[20px] font-[700] leading-[28px] mt-[32px] mb-[8px]">
            {t('mainHeader')}
          </div>
          <div className="text-center text-lkh-text-80 tracking-normal text-[14px] font-[400] leading-[20px] mb-[32px]">
            {t('description.paragraph1')}
            <br />
            {t('description.paragraph2')}
            <br />
            {t('description.paragraph3')}
          </div>
          <FileDownloadBox
            id={EVENT_IDS.DOWNLOAD_APPLICATION}
            isLoading={isDownloading}
            isDone={isDone}
            label={t('download.label')}
            onClick={() => downloadFile(applicationId)}
          />
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="rounded-[4px] bg-lkh-color-surface-60 p-[24px] w-[992px]">
          <div className="text-center tracking-normal text-[16px] font-[700] leading-[24px] mb-[24px]">
            {t('contact.header')}
          </div>
          <div className="flex justify-between flex-col s:flex-row">
            <div className="flex space-x-2 mb-s">
              <div>
                <LocationOnIcon />
              </div>
              <div>
                <div className="grid-cols-subgrid">
                  <div className="col-start-1 text-[12px] font-[700] leading-[18px] tracking-normal text-left inline-block align-middle">
                    {t('contact.location.title')}
                  </div>
                  <div className="col-start-2 text-lkh-text-info text-[10px] font-[400] leading-[16px] tracking-normal text-left pt-s">
                    {t('contact.location.detail1')}
                    <br />
                    {t('contact.location.detail2')}
                  </div>
                </div>
              </div>
            </div>
            <div className="grow mb-s">
              <div className="flex s:justify-center">
                <div className="flex space-x-2">
                  <div>
                    <CallIcon />
                  </div>
                  <div>
                    <div className="grid-cols-subgrid">
                      <div className="col-start-1 text-[12px] font-[700] leading-[18px] tracking-normal text-left inline-block align-middle">
                        {t('contact.number.title')}
                      </div>
                      <div className="col-start-2 text-lkh-text-info text-[10px] font-[400] leading-[16px] tracking-normal text-left pt-s">
                        {t('contact.number.detail')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex space-x-2 mb-s">
              <div>
                <EmailIcon />
              </div>
              <div>
                <div className="grid-cols-subgrid">
                  <div className="col-start-1 text-[12px] font-[700] leading-[18px] tracking-normal text-left inline-block align-middle">
                    <a className="text-lkh-text-80" href={`mailto:${t('contact.email.title')}}`}>
                      {t('contact.email.title')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center grid-my-res">
        <a href="https://www.lkh.de/zusatzversicherung/">{t('footer.navigation')}</a>
      </div>
      <div className="fixed left-0 bottom-0 w-full">
        <LegalFooter />
      </div>
    </div>
  );
};
