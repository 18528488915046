import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import i18n from 'services/translation';
import { getValidPath } from '../../models';
import { RecursiveKeyOf } from 'lkh-portal-ui-library/dist/models';

import { Errors } from 'lkh-portal-ui-library';
import {
  Application,
  PersonRoleEnum,
  CalculationResponse,
  ApplicationService,
  Partner,
  Tariff,
  ApiError,
  Question,
  HealthQuestion,
  GenderEnum
} from '../../models/extension-generated';

export const APPLICATION_PRICE = 'application-price';

export function useApplicationCalculate(
  application: Application,
  healthQuestions: Array<Question>,
  enabled = true
) {
  /**
   * Fields that are listened to on Application level
   * Any change to any of the properties listed will trigger the calculation
   */
  const LISTEN_APPLICATION_PROPERTIES: Array<keyof Application> = ['applicationStart'];

  /**
   * Fields that are listened to on each Partner level recursively
   * Any change to any of the properties listed will trigger the calculation
   */
  const LISTEN_PARTNER_PROPERTIES: Array<RecursiveKeyOf<Partner>> = [
    'birthDate',
    'applicationInformation.comprehensiveHealthTransferValue',
    'applicationInformation.referenceValuePPV',
    'applicationInformation.fundsFromGZ'
  ];

  /**
   * Text to be used as a substitution to an actual user name
   */
  const ANONYMISED_USER_NAME = 'Anonymised';

  /**
   * Text to be used as a substitution to any other non-name property
   */
  const ANONYMISED_OTHER = 'Unknown';

  /**
   * Anonymises all partner data by either removing everything unneseary or modyfing to anonymised format.
   */
  function anonymise(partner: Partner): Partner {
    const anonymisedPartner = cloneDeep(partner);
    anonymisedPartner.firstname = ANONYMISED_USER_NAME;
    anonymisedPartner.lastname = ANONYMISED_USER_NAME;
    anonymisedPartner.gender = GenderEnum.UNKNOWN;
    anonymisedPartner.permanentResidence.city = ANONYMISED_OTHER;
    anonymisedPartner.permanentResidence.street = ANONYMISED_OTHER;
    anonymisedPartner.permanentResidence.houseNumber = ANONYMISED_OTHER;
    anonymisedPartner.permanentResidence.postalCode = ANONYMISED_OTHER;
    delete anonymisedPartner.permanentResidence.email;
    delete anonymisedPartner.permanentResidence.phone;
    delete anonymisedPartner.title;
    delete anonymisedPartner.maritalStatus;
    delete anonymisedPartner.taxNumber;
    delete anonymisedPartner.foreignCountry;
    delete anonymisedPartner.bankDetails;
    return anonymisedPartner;
  }

  /**
   * Creates a valid request for calculation endpoint or returns null if invalid
   *
   * Several fields are anonymised
   */
  function prepareRequst(application: Application): Application | null {
    const applicationRequest = Object.assign({}, application);

    applicationRequest.partners = applicationRequest.partners
      .filter((p) => {
        return (
          p.roles.includes(PersonRoleEnum.INSURED_PERSON) &&
          p.birthDate &&
          p.applicationInformation?.tariffInformation?.selectedTariffs.length
        );
      })
      .map(anonymise);

    if (!application.applicationStart || !applicationRequest.partners.length) {
      return null;
    }

    return applicationRequest;
  }

  /**
   * Runs the calculation API query if possible
   */
  async function calculateQuery(application: Application): Promise<CalculationResponse | null> {
    const request = prepareRequst(application);
    if (!request) {
      return null;
    }

    return ApplicationService.calculatePublicApplication({
      requestBody: request
    });
  }

  /**
   * Concatenates all required fields values to a single string which is then used as a listener (on change for useQuery)
   * This has much better performance as useQuery cannot listen to application/persons directly as it is constantly changing
   */
  function parseChangeDetectionString(): string {
    // Common Application propertoes
    const appFields = (): string => {
      return LISTEN_APPLICATION_PROPERTIES.map((prop) => application[prop]).join(';');
    };

    // Any nested partner properties
    // TODO: check if this should be only partners with role INSURED_PERSON
    const partnerFields = (): string => {
      return application.partners.reduce(
        (acc, curr) =>
          `${acc};` + LISTEN_PARTNER_PROPERTIES.map((path) => get(curr, path)).join(';'),
        ''
      );
    };

    // Selection of the tariffs
    const tariffs = (): string => {
      return application.partners.reduce((acc, curr) => {
        const selectedTariffs = get(
          curr,
          getValidPath<Partner>(`applicationInformation.tariffInformation.selectedTariffs`),
          []
        )
          .map(
            ({ id, addedRiskAmount, highMoneyAmount, hasProspectiveEntitlement }: Tariff) =>
              `${id}|${highMoneyAmount}|${addedRiskAmount}|${hasProspectiveEntitlement}`
          )
          .join(';');
        return `${acc};${curr.id!}:[${selectedTariffs}]`;
      }, '');
    };

    const parseHealthQuestionDetection = (): string => {
      const insuredPartners: Array<Partner> = application.partners.filter(({ roles }) =>
        roles.includes(PersonRoleEnum.INSURED_PERSON)
      );
      const calculationTriggeringHealthQuestionsIds = healthQuestions
        .filter((q) => q.triggerCalculation)
        .map(({ id }) => id);

      const getHealthQuestionDetectionString = (questions: Array<HealthQuestion>): string => {
        return questions.map(({ id, answer }) => `${id}|${answer}}`).join(';');
      };

      const healthQuestionsDetectionString = insuredPartners.reduce((prev, partner) => {
        const healthQuestions = partner.applicationInformation?.health || [];

        const priceDependantQuestions = healthQuestions.filter((question) =>
          calculationTriggeringHealthQuestionsIds.includes(question.id)
        );

        return `${prev};${getHealthQuestionDetectionString(priceDependantQuestions)}`;
      }, '');

      return healthQuestionsDetectionString;
    };

    return appFields() + partnerFields() + tariffs() + parseHealthQuestionDetection();
  }

  const { data, ...rest } = useQuery<CalculationResponse | null, ApiError>(
    [APPLICATION_PRICE, parseChangeDetectionString()],
    () => calculateQuery(application),
    {
      enabled
    }
  );
  const errorMessage = useMemo(() => {
    const errors: Errors = rest.error?.body?.errors || [];

    if (!errors.length) return '';

    const firstError = errors[0];
    const message =
      firstError.message ?? i18n.t(`errorCodes.${firstError.code}`, { ns: 'wizardValidation' });

    return message;
  }, [rest.error]);

  return {
    data,
    message: errorMessage,
    ...rest
  };
}
