import { Components, Theme } from '@mui/material/styles';
import { colors } from 'styles/colors';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    support: true;
  }
}

export const muiChipOverride: Components<Omit<Theme, 'components'>>['MuiChip'] = {
  variants: [
    {
      props: { variant: 'support', color: 'default' },
      style: {
        color: colors.white[100],
        backgroundColor: colors.navigation[80],
        border: `1px solid ${colors.white[100]}`,
        borderRadius: '4px',
        ':hover': {
          backgroundColor: colors.navigation[70]
        },
        ':active': {
          backgroundColor: colors.navigation[60]
        }
      }
    }
  ],
  styleOverrides: {
    colorPrimary: {
      height: '24px',
      backgroundColor: colors.highlight[80]
    }
  }
};
