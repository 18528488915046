import { RecursiveKeyOf } from 'lkh-portal-ui-library/dist/models';
import { Application, Partner } from 'models/extension-generated';
/**
 * Helper for partner type hinting/restrictions
 */
export function partnerField(id: string, path: RecursiveKeyOf<Partner>): string {
  return getValidPath<Application>(`partners[${id}].${path}`);
}

/**
 * Helper for type hinting/restrictions for application fields
 */
export function applicationField(key: keyof Application): string {
  return key;
}

/**
 * Helper for accessing any nested property within a given object
 */
export function getValidPath<T extends object>(path: RecursiveKeyOf<T>): string {
  return path;
}
