import { Components, Theme } from '@mui/material/styles';

export const muiTypographyOverride: Components<Omit<Theme, 'components'>>['MuiTypography'] = {
  defaultProps: {
    variant: 'bodySMRegular',
    variantMapping: {
      //Headline XXXLG
      headlineXXXLGRegular: 'h1',
      headlineXXXLGBold: 'h1',
      headlineXXXLGLight: 'h1',
      headlineXXXLGRegularCaps: 'h1',
      headlineXXXLGBoldCaps: 'h1',
      headlineXXXLGLightCaps: 'h1',
      //Headline XXLG
      headlineXXLGRegular: 'h2',
      headlineXXLGBold: 'h2',
      headlineXXLGLight: 'h2',
      headlineXXLGRegularCaps: 'h2',
      headlineXXLGBoldCaps: 'h2',
      headlineXXLGLightCaps: 'h2',
      //Headline XLG
      headlineXLGRegular: 'h3',
      headlineXLGBold: 'h3',
      headlineXLGLight: 'h3',
      headlineXLGRegularCaps: 'h3',
      headlineXLGBoldCaps: 'h3',
      headlineXLGLightCaps: 'h3',
      //Body-LG
      bodyLGBold: 'span',
      bodyLGRegular: 'span',
      bodyLGLight: 'span',
      bodyLGBoldCaps: 'span',
      bodyLGRegularCaps: 'span',
      bodyLGLightCaps: 'span',
      bodyLGBoldLink: 'span',
      bodyLGRegularLink: 'span',
      bodyLGRegularCapsLink: 'span',
      //Body-MD
      bodyMDBold: 'span',
      bodyMDRegular: 'span',
      bodyMDLight: 'span',
      bodyMDBoldCaps: 'span',
      bodyMDRegularCaps: 'span',
      bodyMDLightCaps: 'span',
      bodyMDBoldLink: 'span',
      bodyMDRegularLink: 'span',
      bodyMDRegularCapsLink: 'span',
      //Body-SM
      bodySMBold: 'span',
      bodySMRegular: 'span',
      bodySMBoldCaps: 'span',
      bodySMRegularCaps: 'span',
      bodySMBoldLink: 'span',
      bodySMRegularLink: 'span',
      bodySMRegularCapsLink: 'span',
      //Body-XS
      bodyXSBold: 'span',
      bodyXSRegular: 'span',
      bodyXSBoldCaps: 'span',
      bodyXSRegularCaps: 'span',
      bodyXSBoldLink: 'span',
      bodyXSRegularLink: 'span',
      bodyXSRegularCapsLink: 'span'
    }
  },
  styleOverrides: {
    root: {
      fontFamily: [
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif'
      ].join(', ')
    }
  }
};
