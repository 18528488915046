import { Iban, IbanService } from 'models/extension-generated';

export const getIbanInfo = async ({ iban }: { iban: string }): Promise<Iban> => {
  try {
    const data = await IbanService.getPublicIbanInfo({ iban });
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error occurred during getIbanInfo:', error);
    throw new Error(`Error occurred during getIbanInfo: ${error}`);
  }
};
