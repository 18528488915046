/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Criteria } from '../models/Criteria';
import type { Question } from '../models/Question';
import type { QuestionResponse } from '../models/QuestionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class QuestionsService {

    /**
     * Retrieves the questions and its text stored in DB
     * @returns QuestionResponse The question retrieves.
     * @throws ApiError
     */
    public static getAllQuestions(): CancelablePromise<QuestionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/question',
        });
    }

    /**
     * Retrieves the questions based on provided criteria
     * @returns QuestionResponse The question retrieves.
     * @throws ApiError
     */
    public static getQuestionsByCriteria({
        requestBody,
    }: {
        requestBody?: Criteria,
    }): CancelablePromise<QuestionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/question',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieves the questions based on provided criteria
     * @returns QuestionResponse The question retrieves.
     * @throws ApiError
     */
    public static getPublicQuestionsByCriteria({
        requestBody,
    }: {
        requestBody?: Criteria,
    }): CancelablePromise<QuestionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/question',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Save the question
     * @returns any Question created.
     * @throws ApiError
     */
    public static saveQuestion({
        requestBody,
    }: {
        requestBody: Question,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/question',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Delete a question
     * @returns any Question deleted.
     * @throws ApiError
     */
    public static deleteQuestionById({
        id,
    }: {
        /**
         * Question unique identifier
         */
        id: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/question/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

}
