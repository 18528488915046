import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import { AccordionHandle } from 'lkh-portal-ui-library';
import { formatGermanDate } from 'utils/dates';
import { SimpleTable } from '../SimpleTable/SimpleTable';
import { optionalValue, formatBoolean } from 'utils/formatters';
import { PaymentContributorDetailProps } from 'pages/DigitalOrderPage/models/insuredPersonDetailModels';

export const PaymentContributorDetail = forwardRef<AccordionHandle, PaymentContributorDetailProps>(
  (props, ref) => {
    const { t } = useTranslation('view360');

    return (
      <div>
        <div className="row reset-container m-t-24 m-b-24">
          <div className="col-6">
            <SimpleTable
              title={t('person.attributes.personalData')}
              rows={[
                {
                  label: t(`person.attributes.salutation`),
                  value: optionalValue(props.salutation)
                },
                {
                  label: t(`person.attributes.academicTitle`),
                  value: optionalValue(props.academicTitle)
                },

                { label: t(`person.attributes.firstName`), value: optionalValue(props.firstName) },
                { label: t(`person.attributes.lastName`), value: optionalValue(props.lastName) },
                {
                  label: t(`person.attributes.birthDate`),
                  value: optionalValue(props.birthDate, formatGermanDate)
                }
              ]}
            />
          </div>

          <div className="col-6">
            <SimpleTable
              title={t('person.attributes.address')}
              rows={[
                { label: t(`person.attributes.street`), value: optionalValue(props.street) },
                {
                  label: t(`person.attributes.houseNumber`),
                  value: optionalValue(props.houseNumber)
                },
                {
                  label: t(`person.attributes.postalCode`),
                  value: optionalValue(props.postalCode)
                },
                { label: t(`person.attributes.city`), value: optionalValue(props.city) },
                { label: t(`person.attributes.country`), value: optionalValue(props.country) }
              ]}
            />
          </div>
        </div>

        <div className="row reset-container m-b-24">
          <div className="col-6">
            <SimpleTable
              title={t('person.attributes.bankData')}
              rows={[
                { label: t(`person.attributes.iban`), value: optionalValue(props.iban) },
                { label: t(`person.attributes.bankName`), value: optionalValue(props.bankName) },
                { label: t(`person.attributes.bic`), value: optionalValue(props.bic) },
                {
                  label: t(`person.attributes.hasPaymentAuthorization`),
                  value: optionalValue(props.hasPaymentAuthorization, formatBoolean)
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
);
