import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { Components, Theme } from '@mui/material/styles';
import { colors } from 'styles/colors';

import { fieldHeight } from '../constants';

export const muiSelectOverride: Components<Omit<Theme, 'components'>>['MuiSelect'] = {
  defaultProps: {
    classes: {
      root: 'bg-white-100'
    }
  },
  styleOverrides: {
    root: {
      height: fieldHeight,
      [`:not(.${outlinedInputClasses.disabled})&:hover .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: colors.primary[80]
      }
    }
  }
};
