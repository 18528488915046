import { CSSProperties } from 'react';

import { Components, Theme } from '@mui/material/styles';
import { colors } from 'styles/colors';

const buttonStyle: { size: { large: CSSProperties; small: CSSProperties } } = {
  size: {
    large: {
      height: '46px',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.08em'
    },
    small: {
      height: '32px',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.08em'
    }
  }
};

export const muiButtonOverride: Components<Omit<Theme, 'components'>>['MuiButton'] = {
  defaultProps: {
    disableRipple: true,
    disableElevation: true
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...(ownerState.variant === 'text' &&
        ownerState.color === 'primary' && {
          '&:hover': {
            color: colors.highlight[80]
          }
        }),
      ...(ownerState.variant === 'contained' &&
        ownerState.color === 'primary' && {
          '&:active': {
            backgroundColor: colors.highlight[70]
          }
        }),
      ...(ownerState.variant === 'outlined' &&
        ownerState.color === 'primary' && {
          '&:hover': {
            color: colors.highlight[80],
            borderColor: colors.highlight[80]
          },
          '&:active': {
            color: colors.highlight[70],
            borderColor: colors.highlight[70]
          }
        }),
      ...(ownerState.size === 'large' && {
        ...buttonStyle.size.large
      }),
      ...(ownerState.size === 'small' && {
        ...buttonStyle.size.small
      })
    })
  }
};
