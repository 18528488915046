import { Components, Theme } from '@mui/material/styles';
import { tabClasses } from '@mui/material/Tab';
import { colors } from 'styles/colors';

export const muiTabOverride: Components<Omit<Theme, 'components'>>['MuiTab'] = {
  defaultProps: {
    disableFocusRipple: true,
    disableTouchRipple: true,
    disableRipple: true
  },
  styleOverrides: {
    root: {
      width: '200px',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
      fontStyle: 'normal',
      color: colors.text[80],
      [`&.${tabClasses.selected}`]: {
        color: colors.text[80]
      },
      [`&.${tabClasses.disabled}`]: {
        color: colors.text[80]
      }
    }
  }
};
