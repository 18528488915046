import i18next from 'i18next';

export enum WizardRoutesEnum {
  Home = '/',
  PrivateData = 'private-data',
  InsuranceHolder = 'insurance-holder',
  Summary = 'summary',
  Done = 'done'
}

export const addPartnerButtonPages = [WizardRoutesEnum.PrivateData];

export const downloadOfferPages = [WizardRoutesEnum.InsuranceHolder, WizardRoutesEnum.Done];

export const downloadProtocolPages = [WizardRoutesEnum.PrivateData];

export const noNextButtonIconPages = [WizardRoutesEnum.Done];

export const getNextButtonText = (pathName: string): string => {
  if (pathName === WizardRoutesEnum.PrivateData) {
    return i18next.t('wizardPrivateHealthInsurance:actions.nextStep');
  } else if (pathName === WizardRoutesEnum.InsuranceHolder) {
    return i18next.t('wizardPrivateHealthInsurance:actions.nextStep');
  } else if (pathName === WizardRoutesEnum.Summary) {
    return i18next.t('wizardPrivateHealthInsurance:actions.summaryStep');
  }
  return i18next.t('wizardPrivateHealthInsurance:actions.nextStep');
};
