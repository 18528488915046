import { RecursiveKeyOf } from 'lkh-portal-ui-library/dist/models';
import { Application, Partner, PersonRoleEnum } from 'models/extension-generated';
import { mapToApplicationFields } from './utils';

export const summaryPageStaticFields: RecursiveKeyOf<Partner>[] = [
  'applicationInformation.acceptsDataProtection',
  'applicationInformation.acceptsToWaiveAdvice',
  'applicationInformation.acceptsContractInformation',
  'applicationInformation.acceptsDataCollection',
  'applicationInformation.acceptsPromotionalPurposes',
  'applicationInformation.acceptsPromotionalPurposesPhone',
  'applicationInformation.acceptsPromotionalPurposesEmail',
  'applicationInformation.hasDownloadedContractInformationDocument'
];

export const getSummaryPageFields = (application: Application): RecursiveKeyOf<Application>[] => {
  const policyHolder = application.partners.find(({ roles }) =>
    roles.includes(PersonRoleEnum.POLICY_HOLDER)
  );
  if (!policyHolder?.id) return [];

  const policyHolderFields = mapToApplicationFields(policyHolder.id, summaryPageStaticFields);

  return policyHolderFields;
};
