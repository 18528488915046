import { DropdownOption } from 'lkh-portal-ui-library';
import moment from 'moment';
import { formatShortDate } from './dates';

/**
1.) WHEN current date is <= 15th of a month THEN earliest start date is 1. of current month
2.) WHEN current date is > 15th of a month THEN earliest start date is 1. of next month
3.) When current month is december THEN earliest start date is first of december for the whole month
 */
export const getInsuranceStartDates = (): Array<DropdownOption> => {
  const options: Array<DropdownOption> = [];
  const now = moment();

  if (now.date() <= 14 || now.month() === 11) {
    now.date(1);
    options.push({
      label: formatShortDate(now.toDate()),
      value: now.toDate().toISOString().slice(0, 10)
    });
  }

  now.date(1);
  for (let i = 0; i < 6; i++) {
    const nextMonth = now.add(1, 'month');
    options.push({
      label: formatShortDate(nextMonth.toDate()),
      value: nextMonth.toDate().toISOString().slice(0, 10)
    });
  }

  return options;
};
