import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3B3B3B',
    maxWidth: 500,
    padding: 12
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#3B3B3B'
  }
});

interface TooltipInfoProps {
  text: string | JSX.Element;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

function TooltipInfo({ text, placement = 'top' }: TooltipInfoProps) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <DarkTooltip arrow leaveDelay={1000} placement={placement} title={text}>
      <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        {isHovered ? (
          <InfoIcon className="text-lkh-color-primary-30" />
        ) : (
          <InfoOutlinedIcon className="text-lkh-color-primary-30" />
        )}
      </div>
    </DarkTooltip>
  );
}

export default TooltipInfo;
