export function getMonths() {
  return Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`
  }));
}

export function getYears(currentYear: number) {
  return Array.from({ length: 101 }, (_, i) => ({
    value: currentYear - i,
    label: `${currentYear - i}`
  }));
}
