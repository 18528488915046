import { Components, Theme } from '@mui/material/styles';
import { colors } from 'styles/colors';

export const muiTabsOverride: Components<Omit<Theme, 'components'>>['MuiTabs'] = {
  styleOverrides: {
    indicator: {
      height: 2,
      backgroundColor: colors.navigation[80]
    }
  }
};
