import { PropsWithChildren, forwardRef } from 'react';

import styles from '../../WizardLayout.module.scss';

export const Main = forwardRef<HTMLDivElement, PropsWithChildren>(function main({ children }, ref) {
  return (
    <main ref={ref} className={styles.main}>
      {children}
    </main>
  );
});
