import classNames from 'classnames';

import styles from './HorizontalSeparator.module.scss';

type HorizontalSeparatorProps = {
  className?: string;
};

export const HorizontalSeparator = ({ className }: HorizontalSeparatorProps) => {
  return <hr className={classNames(styles.separator, className)} />;
};
