import { Components, Theme } from '@mui/material/styles';
import { colors } from 'styles/colors';

export const muiMenuItemOverride: Components<Omit<Theme, 'components'>>['MuiMenuItem'] = {
  defaultProps: {
    disableRipple: true
  },
  styleOverrides: {
    root: {
      padding: '16px',
      color: colors.text[80],
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px'
    }
  }
};
