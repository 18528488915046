import i18n from 'services/translation';
import { ColDef, GroupCellRendererParams, ValueGetterParams } from 'ag-grid-community';

import { TariffRow } from './OfferTariffTable';
import { TableHeaderTooltip } from '../TableHeaderTooltip';

import styles from './OfferTariffTable.module.scss';

export enum ColumnIds {
  Product = 'product',
  Tariff = 'tariff',
  Step = 'step',
  Price = 'price',
  ActionCell = 'actionCell'
}

const commonCellProperties: ColDef = {
  cellClass: styles.cell,
  headerClass: styles.header,
  tooltipComponent: TableHeaderTooltip
};

export const getColumnDefinitions = (allowEditing: boolean): Array<ColDef<TariffRow>> => {
  return [
    {
      ...commonCellProperties,
      field: ColumnIds.Product,
      valueGetter: (params: ValueGetterParams<TariffRow>) => params.data?.product,
      cellRenderer: ({ value }: GroupCellRendererParams) => {
        return value;
      },
      headerName: i18n.t('table.product', { ns: 'wizardOffer' }),
      headerTooltip: i18n.t('table.product', { ns: 'wizardOffer' }),
      flex: 1
    },
    {
      ...commonCellProperties,
      field: ColumnIds.Tariff,
      valueGetter: (params: ValueGetterParams<TariffRow>) => params.data?.label,
      cellRenderer: ({ value }: GroupCellRendererParams) => {
        return value;
      },
      headerName: i18n.t('table.tariff', { ns: 'wizardOffer' }),
      headerTooltip: i18n.t('table.tariff', { ns: 'wizardOffer' }),
      flex: 2
    },
    {
      ...commonCellProperties,
      headerClass: [styles.header, 'ag-right-aligned-header'],
      cellClass: [styles.cell, 'ag-right-aligned-cell'],
      colId: ColumnIds.Price,
      cellStyle: {
        flexDirection: 'row-reverse'
      },
      flex: 1,
      valueGetter: (params: ValueGetterParams<TariffRow>) => params.data?.price,
      cellRenderer: ({ value }: GroupCellRendererParams) => {
        return value;
      },
      headerName: i18n.t('table.price', { ns: 'wizardOffer' }),
      headerTooltip: i18n.t('table.price', { ns: 'wizardOffer' }),
      minWidth: 120
    }
  ];
};
