function WhiteLogo() {
  return (
    <svg width="64" height="31" viewBox="0 0 64 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2685_72969)">
        <path
          d="M0 30.8629L4.03257 5.29136C4.51675 2.22545 7.10829 0.00244141 10.1979 0.00244141H64L59.9674 25.5763C59.4832 28.6399 56.8917 30.8652 53.8021 30.8652H0V30.8629Z"
          fill="white"
        />
        <path
          d="M56.7255 6.24756L53.8227 24.6337H48.8587L49.9977 17.4969H44.9253L43.7863 24.6337H38.8499L41.7527 6.24756H46.6891L45.5224 13.602H50.5948L51.7615 6.24756H56.7255ZM18.6825 20.7643H12.8284L15.1064 6.24756H10.17L7.26953 24.6337H18.0715L18.6825 20.7643ZM31.0015 15.7162C30.9761 14.9636 31.3773 14.563 31.8869 14.0559L39.4724 6.24756H34.0565L27.7413 12.7638C27.437 13.0856 27.2179 13.4446 27.0589 13.8104L28.2532 6.24756H23.3168L20.414 24.6337H25.3504L26.6738 16.2442C26.7222 16.7605 26.9113 17.2399 27.2641 17.7401L32.1428 24.6337H37.402L31.601 17.0292C31.2228 16.5128 31.0176 16.1515 31.0015 15.7162Z"
          fill="#00635F"
        />
      </g>
      <defs>
        <clipPath id="clip0_2685_72969">
          <rect width="64" height="30.8652" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function GreenLogo() {
  return (
    <svg width="80" height="39" viewBox="0 0 80 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3669_436441)">
        <path
          d="M0 38.5785L5.04071 6.61407C5.64594 2.78169 8.88537 0.00292969 12.7473 0.00292969H80L74.9593 31.9703C74.354 35.7998 71.1146 38.5814 67.2527 38.5814H0V38.5785Z"
          fill="#00635F"
        />
        <path
          d="M70.9072 7.80957L67.2787 30.7923H61.0736L62.4973 21.8713H56.1568L54.7331 30.7923H48.5626L52.1911 7.80957H58.3616L56.9033 17.0026H63.2438L64.7021 7.80957H70.9072ZM23.3533 25.9555H16.0358L18.8833 7.80957H12.7128L9.08716 30.7923H22.5896L23.3533 25.9555ZM38.7521 19.6454C38.7204 18.7046 39.2219 18.2039 39.8588 17.57L49.3408 7.80957H42.5708L34.6769 15.9548C34.2965 16.3572 34.0227 16.8058 33.8238 17.2632L35.3167 7.80957H29.1462L25.5177 30.7923H31.6882L33.3425 20.3053C33.403 20.9508 33.6394 21.55 34.0803 22.1752L40.1787 30.7923H46.7527L39.5014 21.2866C39.0288 20.6411 38.7723 20.1895 38.7521 19.6454Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3669_436441">
          <rect width="80" height="38.5816" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export { WhiteLogo, GreenLogo };
