const style = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px'
};
export const muiMuiListItemText = {
  styleOverrides: {
    root: style,
    primary: style
  }
};
