import { flatten } from 'lodash';
import { RecursiveKeyOf } from 'lkh-portal-ui-library/dist/models';
import { Application, HealthQuestion, Partner, PersonRoleEnum } from 'models/extension-generated';
import { mapToApplicationFields } from './utils';

export const insuredPersonStaticFields: RecursiveKeyOf<Partner>[] = [
  'firstname',
  'lastname',
  'birthDate',
  'gender',
  'title',
  'livingInGermany',
  'applicationInformation.tariffInformation.selectedTariffs',
  'applicationInformation.hasNationalInsurance',
  'applicationInformation.hasSupplementaryHospitalInsurance',
  'applicationInformation.hasStatutoryInsurance',
  'applicationInformation.relationshipToHolder',
  'applicationInformation.employer',
  'applicationInformation.employmentGroup',
  'applicationInformation.profession',
  'applicationInformation.trainingStart',
  'applicationInformation.trainingEnd',
  'applicationInformation.isUnderIncomeLimit',
  'applicationInformation.hasSideJob',
  'permanentResidence.street',
  'permanentResidence.houseNumber',
  'permanentResidence.postalCode',
  'permanentResidence.city',
  'foreignCountry',
  'taxNumber',
  'applicationInformation.hasLegalObligationToNotify'
];

const getQuestionFields = ({ id: questionId, details = [] }: HealthQuestion) => {
  const mainAnswerField =
    `applicationInformation.health[${questionId}].answer` as RecursiveKeyOf<Partner>;
  const detailsFields: Array<Array<RecursiveKeyOf<Partner>>> = details.map(
    ({ id: detailId, ...rest }) => {
      const rootPath: RecursiveKeyOf<Partner> =
        `applicationInformation.health[${questionId}].details[${detailId}]` as RecursiveKeyOf<Partner>;
      const fields: Array<RecursiveKeyOf<Partner>> = [
        `${rootPath}.diagnosis` as RecursiveKeyOf<Partner>,
        `${rootPath}.treatmentType` as RecursiveKeyOf<Partner>,
        `${rootPath}.treatmentDone` as RecursiveKeyOf<Partner>,
        `${rootPath}.treatmentStart` as RecursiveKeyOf<Partner>,
        `${rootPath}.treatmentEnd` as RecursiveKeyOf<Partner>,
        `${rootPath}.hasConsenquences` as RecursiveKeyOf<Partner>,
        `${rootPath}.symptomsFreeFrom` as RecursiveKeyOf<Partner>,
        `${rootPath}.hasOperation` as RecursiveKeyOf<Partner>,
        `${rootPath}.operationDate` as RecursiveKeyOf<Partner>,
        `${rootPath}.sickLeaveDuration` as RecursiveKeyOf<Partner>,
        `${rootPath}.doctor` as RecursiveKeyOf<Partner>
      ];
      return fields;
    }
  );
  const allDetailsFields = flatten(detailsFields);

  return [mainAnswerField, ...allDetailsFields];
};

export const getInsuredPersonFields = (
  application: Application,
  partnerId?: string
): RecursiveKeyOf<Application>[] => {
  const currentPartner = application.partners.find(({ id }) => id === partnerId && id);
  const applicationPartners = application.partners.filter(({ roles }) =>
    roles.includes(PersonRoleEnum.INSURED_PERSON)
  );
  const partners = currentPartner ? [currentPartner] : applicationPartners;

  const mapOfPartners: Array<Array<RecursiveKeyOf<Application>>> = partners.map(
    ({ id, applicationInformation = {} }) => {
      if (!id) {
        return [];
      }

      const tariffs = applicationInformation.tariffInformation?.selectedTariffs || [];
      const questions = applicationInformation.health || [];

      const questionsFieldsWithDetails = questions.map(getQuestionFields);
      const tariffFields = tariffs.map(({ id }) => {
        return `applicationInformation.tariffInformation.selectedTariffs[${id}].highMoneyAmount` as RecursiveKeyOf<Partner>;
      });

      const personalInformationFields = mapToApplicationFields(id, insuredPersonStaticFields);
      const tariffInformationFields = mapToApplicationFields(id, tariffFields);
      const healthQuestionsFields = mapToApplicationFields(id, flatten(questionsFieldsWithDetails));

      return [
        'applicationStart',
        ...personalInformationFields,
        ...tariffInformationFields,
        ...healthQuestionsFields
      ];
    }
  );

  return flatten(mapOfPartners);
};
