export const NoQuestionsPictogram = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <rect width="24" height="24" fill="none" />
      <path
        d="M12 23.5209C7.30198 23.5209 3.47998 19.6989 3.47998 15.0009V5.09036L12 0.443359L20.52 5.09036V15.0009C20.52 19.6989 16.698 23.5209 12 23.5209ZM4.01998 5.41136V15.0009C4.01998 19.4011 7.59973 22.9809 12 22.9809C16.4002 22.9809 19.98 19.4019 19.98 15.0009V5.41136L12 1.05836L4.01998 5.41136ZM15 9.38261L13.3087 7.69136L13.6912 7.30961L15 8.61911L17.8087 5.81036L18.1912 6.19211L15 9.38261ZM6.26998 11.4391C6.26998 10.1686 7.13323 9.07661 8.36923 8.78336C8.48173 8.75711 8.56498 8.66111 8.57548 8.54636C8.58598 8.43086 8.52223 8.32136 8.41648 8.27411C7.89673 8.04311 7.56073 7.52636 7.56073 6.95936C7.56073 6.16586 8.20648 5.52086 8.99923 5.52086C9.79423 5.52086 10.4407 6.16586 10.4407 6.95936C10.4407 7.52711 10.1047 8.04311 9.58423 8.27411C9.47848 8.32136 9.41473 8.43086 9.42523 8.54636C9.43573 8.66111 9.51898 8.75711 9.63148 8.78336C10.293 8.94011 10.8697 9.33761 11.2552 9.90236L11.7015 9.59786C11.3512 9.08486 10.8682 8.68736 10.3095 8.44286C10.7287 8.07236 10.98 7.53536 10.98 6.95861C10.98 5.86736 10.0912 4.98011 8.99848 4.98011C7.90723 4.98011 7.01998 5.86736 7.01998 6.95861C7.01998 7.53461 7.27123 8.07161 7.68973 8.44211C6.51148 8.95286 5.72923 10.1146 5.72923 11.4391V15.7509H6.26923V11.4391H6.26998ZM16.5 13.0209H10.5C10.3507 13.0209 10.23 12.9001 10.23 12.7509V11.2509C10.23 11.1016 10.3507 10.9809 10.5 10.9809H16.5C16.6492 10.9809 16.77 11.1016 16.77 11.2509V12.7509C16.77 12.9001 16.6492 13.0209 16.5 13.0209ZM10.77 12.4809H16.23V11.5209H10.77V12.4809ZM16.5 16.0209H10.5C10.3507 16.0209 10.23 15.9001 10.23 15.7509V14.2509C10.23 14.1016 10.3507 13.9809 10.5 13.9809H16.5C16.6492 13.9809 16.77 14.1016 16.77 14.2509V15.7509C16.77 15.9001 16.6492 16.0209 16.5 16.0209ZM10.77 15.4809H16.23V14.5209H10.77V15.4809ZM16.5 19.0209H10.5C10.3507 19.0209 10.23 18.9001 10.23 18.7509V17.2509C10.23 17.1016 10.3507 16.9809 10.5 16.9809H16.5C16.6492 16.9809 16.77 17.1016 16.77 17.2509V18.7509C16.77 18.9001 16.6492 19.0209 16.5 19.0209ZM10.77 18.4809H16.23V17.5209H10.77V18.4809Z"
        fill="currentColor"
      />
    </svg>
  );
};
