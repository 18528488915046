import { ReactNode } from 'react';
import { Contact as PortalContact, Person as PortalPerson } from '../models/portal-generated';
import {
  Contact as ExtensionContact,
  Person as ExtensionPerson
} from '../models/extension-generated';
import i18n from '../services/translation';

/**
 * Ensures the correct formatting of possibly nullable values
 *
 * Accepts optional formatter to apply and encapsulates the formatter call to catch errors.
 * Whenever the value is not provided (it is nullable or exception is thrown), this formatter returns "-".
 * Actual (formatted) value is used otherwise
 *
 * @param value any value to be handled error-free
 * @param formatter [optional] callback function for formatting of the value
 * @returns correctly formatted value or "-"
 */
export function optionalValue(
  value?: any,
  formatter?: (value: any) => string | ReactNode
): string | ReactNode {
  try {
    const isEmpty = value.toString() === '';

    if (!isEmpty && formatter) {
      return formatter(value);
    } else if (!isEmpty) {
      return value;
    }

    return '-';
  } catch (e) {
    return '-';
  }
}

/**
 * Address formatter
 *
 * Note: data types are the same origin (base model) but exported twice
 *
 * @param address proposal contact object
 * @returns address formatted
 */
export function formatAddress(address?: PortalContact): string;
export function formatAddress(address?: ExtensionContact): string;
export function formatAddress(address: any): string {
  return `${optionalValue(address?.street)} ${optionalValue(address?.houseNumber)}, ${optionalValue(
    address?.postalCode
  )} ${optionalValue(address?.city)}`;
}

/**
 * Person name formatter
 *
 * Note: data types are the same origin (base model) but exported twice
 *
 * @param person object
 * @returns formatted string
 */
export function formatName(person?: PortalPerson): string;
export function formatName(person?: ExtensionPerson): string;
export function formatName(person: any): string {
  return `${person.lastname}, ${person.firstname}`;
}

/**
 * Formats known enum to a translated string
 *
 * @param value from the enum
 * @param enumName name of the enum (case-sensitive exact match required!)
 * @param ns i18next namespace
 * @returns translated value from an enum
 */
export function formatEnum(value: string | undefined, enumName: string, ns: string): string {
  if (!value) {
    return '';
  }
  return i18n.t(`${enumName}.${value}`, { ns });
}

/**
 * Formats yes/no field
 *
 * Results in Ja/Nein in case a boolean is provided, otherwise empty string.
 *
 * @param value boolean or empty
 * @returns formatted boolean
 */
export function formatBoolean(value?: boolean): string {
  if (typeof value !== 'boolean') {
    return '';
  }

  const ns = 'view360';
  return value ? i18n.t('utils.true', { ns }) : i18n.t('utils.false', { ns });
}

/**
 * Appends the "%" sign if valid number, otherwise empty string.
 *
 * @param value number or undefined
 * @returns formatted number as string
 */
export function formatPercent(value?: number): string {
  if (typeof value !== 'number') {
    return '';
  }
  return `${value}%`;
}
