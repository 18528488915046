/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GenderEnum } from './GenderEnum';
import type { MaritalStatusEnum } from './MaritalStatusEnum';
import type { PersonRoleEnum } from './PersonRoleEnum';
import type { SalutationEnum } from './SalutationEnum';
import type { TitleEnum } from './TitleEnum';

/**
 * This is the generic person object
 */
export type Person = {
    /**
     * First name of the Person.
     */
    firstname: string;
    /**
     * Last name of the Person.
     */
    lastname: string;
    title?: TitleEnum;
    /**
     * Birth date of the Person.
     */
    birthDate: string;
    gender: GenderEnum;
    salutation: SalutationEnum;
    roles: Array<PersonRoleEnum>;
    maritalStatus?: MaritalStatusEnum;
    personType?: Person.personType;
};

export namespace Person {

    export enum personType {
        NATURAL = 'NATURAL',
        LEGAL = 'LEGAL',
    }


}

