import { RecursiveKeyOf } from 'lkh-portal-ui-library/dist/models';
import { partnerField } from 'models';
import { Application, Partner } from 'models/extension-generated';

/**
 * Helper for mapping the fields to the partner version (indexed with partner[id])
 */
export function mapToApplicationFields(
  id: string,
  fields: Array<RecursiveKeyOf<Partner>>
): Array<RecursiveKeyOf<Application>> {
  return fields.map((field) => partnerField(id, field) as RecursiveKeyOf<Application>);
}
