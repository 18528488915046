import { CSSProperties } from 'react';

import figmaTypographyTokens from './tokens/text.styles.tokens.json';
import { TypographyOptions } from '@mui/material/styles/createTypography';

const lkhTypographyRoot = figmaTypographyTokens['lkh-text'];

declare module '@mui/material/styles' {
  interface TypographyVariants {
    //Headline-XXXL
    headlineXXXLGBold: CSSProperties;
    headlineXXXLGRegular: CSSProperties;
    headlineXXXLGLight: CSSProperties;
    headlineXXXLGBoldCaps: CSSProperties;
    headlineXXXLGRegularCaps: CSSProperties;
    headlineXXXLGLightCaps: CSSProperties;
    //Headline-XXL
    headlineXXLGBold: CSSProperties;
    headlineXXLGRegular: CSSProperties;
    headlineXXLGLight: CSSProperties;
    headlineXXLGBoldCaps: CSSProperties;
    headlineXXLGRegularCaps: CSSProperties;
    headlineXXLGLightCaps: CSSProperties;
    //Headline-XL
    headlineXLGBold: CSSProperties;
    headlineXLGRegular: CSSProperties;
    headlineXLGLight: CSSProperties;
    headlineXLGBoldCaps: CSSProperties;
    headlineXLGRegularCaps: CSSProperties;
    headlineXLGLightCaps: CSSProperties;
    //Body-LG
    bodyLGBold: CSSProperties;
    bodyLGRegular: CSSProperties;
    bodyLGLight: CSSProperties;
    bodyLGBoldCaps: CSSProperties;
    bodyLGRegularCaps: CSSProperties;
    bodyLGLightCaps: CSSProperties;
    bodyLGBoldLink: CSSProperties;
    bodyLGRegularLink: CSSProperties;
    bodyLGRegularCapsLink: CSSProperties;
    //Body-MD
    bodyMDBold: CSSProperties;
    bodyMDRegular: CSSProperties;
    bodyMDLight: CSSProperties;
    bodyMDBoldCaps: CSSProperties;
    bodyMDRegularCaps: CSSProperties;
    bodyMDLightCaps: CSSProperties;
    bodyMDBoldLink: CSSProperties;
    bodyMDRegularLink: CSSProperties;
    bodyMDRegularCapsLink: CSSProperties;
    //Body-SM
    bodySMBold: CSSProperties;
    bodySMRegular: CSSProperties;
    bodySMBoldCaps: CSSProperties;
    bodySMRegularCaps: CSSProperties;
    bodySMBoldLink: CSSProperties;
    bodySMRegularLink: CSSProperties;
    bodySMRegularCapsLink: CSSProperties;
    //Body-XS
    bodyXSBold: CSSProperties;
    bodyXSRegular: CSSProperties;
    bodyXSBoldCaps: CSSProperties;
    bodyXSRegularCaps: CSSProperties;
    bodyXSBoldLink: CSSProperties;
    bodyXSRegularLink: CSSProperties;
    bodyXSRegularCapsLink: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    //Headline-XXXL
    headlineXXXLGBold?: CSSProperties;
    headlineXXXLGRegular?: CSSProperties;
    headlineXXXLGLight?: CSSProperties;
    headlineXXXLGBoldCaps?: CSSProperties;
    headlineXXXLGRegularCaps?: CSSProperties;
    headlineXXXLGLightCaps?: CSSProperties;
    //Headline-XXL
    headlineXXLGBold?: CSSProperties;
    headlineXXLGRegular?: CSSProperties;
    headlineXXLGLight?: CSSProperties;
    headlineXXLGBoldCaps?: CSSProperties;
    headlineXXLGRegularCaps?: CSSProperties;
    headlineXXLGLightCaps?: CSSProperties;
    //Headline-XL
    headlineXLGBold?: CSSProperties;
    headlineXLGRegular?: CSSProperties;
    headlineXLGLight?: CSSProperties;
    headlineXLGBoldCaps?: CSSProperties;
    headlineXLGRegularCaps?: CSSProperties;
    headlineXLGLightCaps?: CSSProperties;
    //Body-LG
    bodyLGBold?: CSSProperties;
    bodyLGRegular?: CSSProperties;
    bodyLGLight?: CSSProperties;
    bodyLGBoldCaps?: CSSProperties;
    bodyLGRegularCaps?: CSSProperties;
    bodyLGLightCaps?: CSSProperties;
    bodyLGBoldLink?: CSSProperties;
    bodyLGRegularLink?: CSSProperties;
    bodyLGRegularCapsLink?: CSSProperties;
    //Body-MD
    bodyMDBold?: CSSProperties;
    bodyMDRegular?: CSSProperties;
    bodyMDLight?: CSSProperties;
    bodyMDBoldCaps?: CSSProperties;
    bodyMDRegularCaps?: CSSProperties;
    bodyMDLightCaps?: CSSProperties;
    bodyMDBoldLink?: CSSProperties;
    bodyMDRegularLink?: CSSProperties;
    bodyMDRegularCapsLink?: CSSProperties;
    //Body-SM
    bodySMBold?: CSSProperties;
    bodySMRegular?: CSSProperties;
    bodySMBoldCaps?: CSSProperties;
    bodySMRegularCaps?: CSSProperties;
    bodySMBoldLink?: CSSProperties;
    bodySMRegularLink?: CSSProperties;
    bodySMRegularCapsLink?: CSSProperties;
    //Body-XS
    bodyXSBold?: CSSProperties;
    bodyXSRegular?: CSSProperties;
    bodyXSBoldCaps?: CSSProperties;
    bodyXSRegularCaps?: CSSProperties;
    bodyXSBoldLink?: CSSProperties;
    bodyXSRegularLink?: CSSProperties;
    bodyXSRegularCapsLink?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: false;
    h2: false;
    h3: false;
    h4: false;
    h5: false;
    h6: false;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    caption: false;
    overline: false;
    //LKH variants
    //Headline-XXXL
    headlineXXXLGBold: true;
    headlineXXXLGRegular: true;
    headlineXXXLGLight: true;
    headlineXXXLGBoldCaps: true;
    headlineXXXLGRegularCaps: true;
    headlineXXXLGLightCaps: true;
    //Headline-XXL
    headlineXXLGBold: true;
    headlineXXLGRegular: true;
    headlineXXLGLight: true;
    headlineXXLGBoldCaps: true;
    headlineXXLGRegularCaps: true;
    headlineXXLGLightCaps: true;
    //Headline-XL
    headlineXLGBold: true;
    headlineXLGRegular: true;
    headlineXLGLight: true;
    headlineXLGBoldCaps: true;
    headlineXLGRegularCaps: true;
    headlineXLGLightCaps: true;
    //Body-LG
    bodyLGBold: true;
    bodyLGRegular: true;
    bodyLGLight: true;
    bodyLGBoldCaps: true;
    bodyLGRegularCaps: true;
    bodyLGLightCaps: true;
    bodyLGBoldLink: true;
    bodyLGRegularLink: true;
    bodyLGRegularCapsLink: true;
    //Body-MD
    bodyMDBold: true;
    bodyMDRegular: true;
    bodyMDLight: true;
    bodyMDBoldCaps: true;
    bodyMDRegularCaps: true;
    bodyMDLightCaps: true;
    bodyMDBoldLink: true;
    bodyMDRegularLink: true;
    bodyMDRegularCapsLink: true;
    //Body-SM
    bodySMBold: true;
    bodySMRegular: true;
    bodySMBoldCaps: true;
    bodySMRegularCaps: true;
    bodySMBoldLink: true;
    bodySMRegularLink: true;
    bodySMRegularCapsLink: true;
    //Body-XS
    bodyXSBold: true;
    bodyXSRegular: true;
    bodyXSBoldCaps: true;
    bodyXSRegularCaps: true;
    bodyXSBoldLink: true;
    bodyXSRegularLink: true;
    bodyXSRegularCapsLink: true;
  }
}

export const typographyOverride: TypographyOptions = {
  //Remove unneseccary variants
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  body1: undefined,
  body2: undefined,
  caption: undefined,
  overline: undefined,
  //Custom LKH variants
  //Headline-XXXLG
  headlineXXXLGBold: lkhTypographyRoot['Headline-xxxlg'].bold.$value as CSSProperties,
  headlineXXXLGRegular: lkhTypographyRoot['Headline-xxxlg'].regular.$value as CSSProperties,
  headlineXXXLGLight: lkhTypographyRoot['Headline-xxxlg'].light.$value as CSSProperties,
  headlineXXXLGBoldCaps: lkhTypographyRoot['Headline-xxxlg']['bold-caps'].$value as CSSProperties,
  headlineXXXLGRegularCaps: lkhTypographyRoot['Headline-xxxlg']['regular-caps']
    .$value as CSSProperties,
  headlineXXXLGLightCaps: lkhTypographyRoot['Headline-xxxlg']['light-caps'].$value as CSSProperties,
  //Headline-XXLG
  headlineXXLGBold: lkhTypographyRoot['Headline-xxlg'].bold.$value as CSSProperties,
  headlineXXLGRegular: lkhTypographyRoot['Headline-xxlg'].regular.$value as CSSProperties,
  headlineXXLGLight: lkhTypographyRoot['Headline-xxlg'].light.$value as CSSProperties,
  headlineXXLGBoldCaps: lkhTypographyRoot['Headline-xxlg']['bold-caps'].$value as CSSProperties,
  headlineXXLGRegularCaps: lkhTypographyRoot['Headline-xxlg']['regular-caps']
    .$value as CSSProperties,
  headlineXXLGLightCaps: lkhTypographyRoot['Headline-xxlg']['light-caps'].$value as CSSProperties,
  //Headline-XLG
  headlineXLGBold: lkhTypographyRoot['Headline-xlg'].bold.$value as CSSProperties,
  headlineXLGRegular: lkhTypographyRoot['Headline-xlg'].regular.$value as CSSProperties,
  headlineXLGLight: lkhTypographyRoot['Headline-xlg'].light.$value as CSSProperties,
  headlineXLGBoldCaps: lkhTypographyRoot['Headline-xlg']['bold-caps'].$value as CSSProperties,
  headlineXLGRegularCaps: lkhTypographyRoot['Headline-xlg']['regular-caps'].$value as CSSProperties,
  headlineXLGLightCaps: lkhTypographyRoot['Headline-xlg']['light-caps'].$value as CSSProperties,
  //Body-LG
  bodyLGBold: lkhTypographyRoot['Body-lg'].bold.$value as CSSProperties,
  bodyLGRegular: lkhTypographyRoot['Body-lg'].regular.$value as CSSProperties,
  bodyLGLight: lkhTypographyRoot['Body-lg'].light.$value as CSSProperties,
  bodyLGBoldCaps: lkhTypographyRoot['Body-lg']['bold-caps'].$value as CSSProperties,
  bodyLGRegularCaps: lkhTypographyRoot['Body-lg']['regular-caps'].$value as CSSProperties,
  bodyLGLightCaps: lkhTypographyRoot['Body-lg']['light-caps'].$value as CSSProperties,
  bodyLGBoldLink: lkhTypographyRoot['Body-lg'].boldlink.$value as CSSProperties,
  bodyLGRegularLink: lkhTypographyRoot['Body-lg'].regularlink.$value as CSSProperties,
  bodyLGRegularCapsLink: lkhTypographyRoot['Body-lg'].regularcapslink.$value as CSSProperties,
  //Body-MD
  bodyMDBold: lkhTypographyRoot['Body-md'].bold.$value as CSSProperties,
  bodyMDRegular: lkhTypographyRoot['Body-md'].regular.$value as CSSProperties,
  bodyMDLight: lkhTypographyRoot['Body-md'].light.$value as CSSProperties,
  bodyMDBoldCaps: lkhTypographyRoot['Body-md']['bold-caps'].$value as CSSProperties,
  bodyMDRegularCaps: lkhTypographyRoot['Body-md']['regular-caps'].$value as CSSProperties,
  bodyMDLightCaps: lkhTypographyRoot['Body-md']['light-caps'].$value as CSSProperties,
  bodyMDBoldLink: lkhTypographyRoot['Body-md'].boldlink.$value as CSSProperties,
  bodyMDRegularLink: lkhTypographyRoot['Body-md'].regularlink.$value as CSSProperties,
  bodyMDRegularCapsLink: lkhTypographyRoot['Body-md'].regularcapslink.$value as CSSProperties,
  //Body-SM
  bodySMBold: lkhTypographyRoot['Body-sm'].bold.$value as CSSProperties,
  bodySMRegular: lkhTypographyRoot['Body-sm'].regular.$value as CSSProperties,
  bodySMBoldCaps: lkhTypographyRoot['Body-sm']['bold-caps'].$value as CSSProperties,
  bodySMRegularCaps: lkhTypographyRoot['Body-sm']['regular-caps'].$value as CSSProperties,
  bodySMBoldLink: lkhTypographyRoot['Body-sm'].boldlink.$value as CSSProperties,
  bodySMRegularLink: lkhTypographyRoot['Body-sm'].regularlink.$value as CSSProperties,
  bodySMRegularCapsLink: lkhTypographyRoot['Body-sm'].regularcapslink.$value as CSSProperties,
  //Body-XS
  bodyXSBold: lkhTypographyRoot['Body-xs'].bold.$value as CSSProperties,
  bodyXSRegular: lkhTypographyRoot['Body-xs'].regular.$value as CSSProperties,
  bodyXSBoldCaps: lkhTypographyRoot['Body-xs']['bold-caps'].$value as CSSProperties,
  bodyXSRegularCaps: lkhTypographyRoot['Body-xs']['regular-caps'].$value as CSSProperties,
  bodyXSBoldLink: lkhTypographyRoot['Body-xs'].boldlink.$value as CSSProperties,
  bodyXSRegularLink: lkhTypographyRoot['Body-xs'].regularlink.$value as CSSProperties,
  bodyXSRegularCapsLink: lkhTypographyRoot['Body-xs'].regularcapslink.$value as CSSProperties,
  button: {
    textTransform: 'none'
  },
  fontFamily: [
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    'sans-serif'
  ].join(', ')
};
