import { PaletteOptions } from '@mui/material';
import { colors } from 'styles/colors';

export const paletteOverride: PaletteOptions = {
  primary: {
    main: colors.primary[80],
    light: colors.highlight[20],
    dark: colors.highlight[80],
    contrastText: colors.white[100]
  },
  secondary: {
    main: colors.secondary[80]
  },
  error: {
    main: colors.danger[60]
  },
  warning: {
    main: colors.warning[60]
  },
  success: {
    main: colors.success[60]
  }
};
