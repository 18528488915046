import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import {
  FileDownloadBox,
  Form,
  Label,
  formModelGet,
  useApplicationHelpers
} from 'lkh-portal-ui-library';
import { partnerField } from 'models';
import { PersonRoleEnum } from 'models/extension-generated';
import { useTranslation } from 'react-i18next';

export const AccompaniedDocumentsDownload = ({ onClick }: { onClick?: () => void }) => {
  const { t } = useTranslation('summaryPage');
  const { state } = useHealthInsuranceContext();
  const { getSinglePartnerByRole } = useApplicationHelpers(state.model);

  const { id: holderId = '' } = getSinglePartnerByRole(PersonRoleEnum.POLICY_HOLDER);
  const hasDownloadedContractInformationDocumentPath = partnerField(
    holderId,
    'applicationInformation.hasDownloadedContractInformationDocument'
  );
  const hasDownloadedContractInformationDocument = formModelGet(
    state.model,
    hasDownloadedContractInformationDocumentPath
  );
  const { hasError, message } = Form.hooks.useFormComponent(
    hasDownloadedContractInformationDocumentPath
  );

  return (
    <>
      <FileDownloadBox
        isDone={hasDownloadedContractInformationDocument}
        label={t('downloadDocument')}
        onClick={onClick}
      />
      {hasError && (
        <div className="text-error-700">
          <Label regular>{message || 'hasError'}</Label>
        </div>
      )}
    </>
  );
};
