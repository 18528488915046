import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { TertiaryButton } from 'lkh-portal-ui-library';
import { useWizardNavigation } from 'hooks/useWizardNavigation';
import { WizardRoutesEnum } from 'utils/actions';
import { useTranslation } from 'react-i18next';

export const BackButton = () => {
  const { t } = useTranslation();
  const { back, currentScreen } = useWizardNavigation();

  return (
    <TertiaryButton onClick={back} disabled={currentScreen === WizardRoutesEnum.PrivateData}>
      <ArrowBackOutlinedIcon className="text-primary" />
      <span className="tracking-normal text-[14px] font-[700] leading-[20px] pl-[8px]">
        {t('wizard.actions.back')}
      </span>
    </TertiaryButton>
  );
};
