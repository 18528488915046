import { useTranslation } from 'react-i18next';
import { Icon, DownloadIcon, PrimaryButton, formModelGet } from 'lkh-portal-ui-library';
import { useState } from 'react';
import { cloneDeep } from 'lodash';

import styles from './DownloadButton.module.scss';
import classNames from 'classnames';
import { useHealthInsuranceContext } from 'contexts/HealthInsuranceContext';
import { downloadData, downloadDisclaimerDocument } from 'utils/fetchDocument';
import { WizardRoutesEnum, downloadOfferPages } from 'utils/actions';
import axios from 'axios';
import {
  Application,
  ApplicationService,
  Partner,
  PersonRoleEnum
} from 'models/extension-generated';
import { DownloadOfferDialog } from './DownloadOfferDialog';
import { partnerField } from 'models';

type DownloadButtonProps = {
  currentPage: WizardRoutesEnum;
};

export const downloadOfferDocument = async (application: Application) => {
  const response = await axios.post(
    '/api/public/new/application/offer',
    { ...application, id: application.id },
    { responseType: 'blob' }
  );

  downloadData(response.data, `${application.id}-VS.pdf`);
};

export const DownloadButton = ({ currentPage }: DownloadButtonProps) => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { state, reducer, partnerId } = useHealthInsuranceContext();

  const hasConsentPath = partnerField(partnerId, 'applicationInformation.acceptsDataProtection');
  const hasConsent = formModelGet(state.model, hasConsentPath);

  const buttonStyles = classNames('m-t-16 d-flex j-c-center ', styles.downloadButton);

  const handleConsentClick = (hasConsent: boolean) => {
    reducer.updateValue({
      key: hasConsentPath,
      value: hasConsent
    });
  };

  const downloadOffer = async () => {
    const cloned = { ...cloneDeep(state.model) };

    cloned.partners = cloned.partners.filter((p: Partner) => {
      if (p.roles.includes(PersonRoleEnum.INSURED_PERSON)) {
        // This is mandatory for insured partners to save in proposal
        return p.birthDate && p.gender;
      }
      return true;
    });
    const applicationId: string = await (async () => {
      if (cloned.id) {
        return cloned.id;
      }
      const { id } = await ApplicationService.createPublicApplication({ requestBody: cloned });
      reducer.updateValue({
        key: 'id',
        value: id
      });
      return id;
    })();

    await downloadOfferDocument({
      ...cloned,
      id: applicationId
    });
    setIsDialogOpen(false);
  };

  return (
    <>
      {downloadOfferPages.includes(currentPage) && (
        <>
          <DownloadOfferDialog
            hasConsent={hasConsent}
            onDownload={downloadOffer}
            onDownloadDisclaimerDocument={() =>
              downloadDisclaimerDocument(state.model.applicationStart)
            }
            onConsentClick={handleConsentClick}
            onClose={() => {
              setIsDialogOpen(false);
            }}
            isOpen={isDialogOpen}
          />

          <PrimaryButton
            className={buttonStyles}
            onClick={() => {
              setIsDialogOpen((prev) => !prev);
            }}
          >
            <>
              <Icon icon={<DownloadIcon />} className="m-r-8" />
              {t('actions.downloadOffer.label')}
            </>
          </PrimaryButton>
        </>
      )}
    </>
  );
};
