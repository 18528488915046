/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum QuestionTypeEnum {
    CLOSED_QUESTION = 'CLOSED_QUESTION',
    NUMBER_QUESTION = 'NUMBER_QUESTION',
    TEXT_QUESTION = 'TEXT_QUESTION',
    INT_QUESTION = 'INT_QUESTION',
    ENUMERATED_QUESTION = 'ENUMERATED_QUESTION',
    DATE_QUESTION = 'DATE_QUESTION',
}
