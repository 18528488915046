import {
  Criteria,
  CriteriaType,
  QuestionsService,
  SearchOperationEnum,
  Tariff
} from 'models/extension-generated';
import { calculateAge } from './dates';

const getDateBirthCriteria = (birthDate: string, insuranceStart: string) => {
  const age = calculateAge(birthDate, insuranceStart);
  return age >= 6
    ? { key: 'tag', value: 'ADULT', operation: SearchOperationEnum.LIKE }
    : { key: 'tag', value: 'CHILD', operation: SearchOperationEnum.LIKE };
};

const getRequestBody = ({
  selectedTariffsIds,
  hasBirthCriteria,
  birthDate,
  insuranceStart
}: {
  selectedTariffsIds: string[];
  hasBirthCriteria: boolean;
  birthDate: string;
  insuranceStart: string;
}): { requestBody: Criteria } => {
  const searchCriteria = [
    {
      criteriaType: CriteriaType.AND,
      criteria: [
        {
          key: 'tariffs.name',
          operation: SearchOperationEnum.IN,
          value: selectedTariffsIds
        },
        { key: 'product', value: 'PUBLIC', operation: SearchOperationEnum.LIKE }
      ]
    }
  ];

  if (hasBirthCriteria) {
    searchCriteria[0].criteria.push(getDateBirthCriteria(birthDate, insuranceStart));
  }

  return {
    requestBody: {
      page: 0,
      size: 0,
      sort: [{ field: 'orderNo', order: 'ASCENDING' }],
      searchCriteria
    }
  };
};

export const getQuestionsByCriteria = async ({
  tariffs,
  hasBirthCriteria = false,
  birthDate,
  insuranceStart
}: {
  tariffs: Array<Tariff>;
  hasBirthCriteria?: boolean;
  birthDate: string;
  insuranceStart: string;
}) => {
  const selectedTariffsIds = tariffs.map((tariff) => tariff.id);
  const requestBody = getRequestBody({
    selectedTariffsIds,
    hasBirthCriteria,
    birthDate,
    insuranceStart
  });
  const response = await QuestionsService.getPublicQuestionsByCriteria(requestBody);

  return response.questions;
};
