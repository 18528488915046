import { ITooltipParams } from 'ag-grid-community';
import { TooltipWrapper } from '../TableTooltipWrapper';
import { Body } from 'lkh-portal-ui-library';

export const TableHeaderTooltip = (props: ITooltipParams) => {
  const isHeader = props.rowIndex === undefined;
  const header = props.value || '';

  if (!isHeader) return <></>;

  return (
    <TooltipWrapper>
      <Body small bold>
        {header}
      </Body>
    </TooltipWrapper>
  );
};
