import { ReactNode } from 'react';

import classNames from 'classnames';

import Typography from '@mui/material/Typography';

export type SimpleTableProps = {
  title?: string;
  titleClass?: string;
  headingClass?: string;
  dataClass?: string;
  rows: Array<{
    label: string;
    value: string | number | ReactNode;
  }>;
};

export const SimpleTable = ({
  rows,
  title,
  titleClass,
  headingClass,
  dataClass
}: SimpleTableProps) => {
  return (
    <>
      {title && (
        <div translate="no" className="mb-[16px]">
          <Typography variant="bodyMDRegular" className={classNames('text-text-60', titleClass)}>
            {title}
          </Typography>
        </div>
      )}
      <div className="w-full text-left">
        {rows.map((row) => (
          <div
            key={`$simple-table-${row.label}-${row.value}`}
            className="flex gap-[16px] w-full text-left px-0 py-[8px] border-solid border-b-[1px] border-l-0 border-r-0 border-t-0 border-b-text-20"
          >
            <div translate="no" className="flex-[1_1_0%] overflow-hidden text-ellipsis self-start">
              <Typography
                variant="bodySMBold"
                className={classNames('text-text-80 py-[8px]', headingClass)}
              >
                {row.label}
              </Typography>
            </div>
            <div translate="no" className="flex-[1_1_0%] overflow-hidden text-ellipsis self-end">
              <Typography
                variant="bodySMRegular"
                className={classNames('text-text-100 py-[8px]', dataClass)}
              >
                {row.value}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
