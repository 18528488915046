import { RecursiveKeyOf } from 'lkh-portal-ui-library/dist/models';
import { Application } from 'models/extension-generated';
import { WizardRoutesEnum } from 'utils/actions';
import { getInsuredPersonFields } from './insuredPersonPage';
import { getInsuranceHolderFields } from './InsuranceHolderPage';
import { getSummaryPageFields } from './summaryPage';

/**
 * Retrieves joined validation data based on the specified wizard route.
 *
 * @param route - The current wizard route.
 * @param application - The application object containing data.
 * @param currentPartner - Optional. The current partner's identifier.
 * @returns An array of keys representing joined validation data for the specified route.
 *
 * @remarks
 * This function determines the validation data to be retrieved based on the provided wizard route.
 * It combines fields with values and the schema for the current route in the wizard.
 *
 * @example
 * const validationData = getJoinedValidationByRoute(
 *   WizardRoutesEnum.PrivateData,
 *   myApplication,
 *   'partnerA',
 *   true
 * );
 */
export function getSchemaForRoute(
  route: WizardRoutesEnum,
  application: Application,
  currentPartner?: string
): Array<RecursiveKeyOf<Application>> {
  switch (route) {
    case WizardRoutesEnum.PrivateData:
      return getInsuredPersonFields(application, currentPartner);
    case WizardRoutesEnum.InsuranceHolder:
      return getInsuranceHolderFields(application);
    case WizardRoutesEnum.Summary:
      return getSummaryPageFields(application);

    default:
      return [];
  }
}
