/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Iban } from '../models/Iban';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IbanService {

    /**
     * Get IBAN information
     * @returns Iban Iban info successfully retrieved
     * @throws ApiError
     */
    public static getIbanInfo({
        iban,
    }: {
        /**
         * Iban to be evaluated
         */
        iban: string,
    }): CancelablePromise<Iban> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/iban/{iban}',
            path: {
                'iban': iban,
            },
            errors: {
                400: `Iban not valid.`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get IBAN information
     * @returns Iban Iban info successfully retrieved
     * @throws ApiError
     */
    public static getPublicIbanInfo({
        iban,
    }: {
        /**
         * Iban to be evaluated
         */
        iban: string,
    }): CancelablePromise<Iban> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/iban/{iban}',
            path: {
                'iban': iban,
            },
            errors: {
                400: `Iban not valid.`,
                500: `Internal Server Error`,
            },
        });
    }

}
